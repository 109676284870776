import React, { Component } from "react";
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import jsonFetch from '../common/api';
import IMAGES from '../common/images';
import PreloginVideoSlider from '../components/common/PreloginVideoSlider';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import TrialForm from "../components/common/TrialForm";
//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
// import axios from 'axios';
// import Script from '@gumgum/react-script-tag';

class Webinar extends Component {
  constructor() {
      super();
      this.state = {
        currentUser: null,
        inlineLoading: false,
        Banners: null,
        Popular: null,
        visible: false,
        vid_link: null,
        SliderPlaying: true,
        muted: false,
        // webinarcode: <script src="https://event.webinarjam.com/register/1n5y4uy/embed-bar?buttonText=Register&buttonBgColor=%23000000&buttonBgOpacity=0.5&barBgColor=%2329b6f6&barBgOpacity=0.95"></script>
      };
  }

  async componentDidMount(){
    document.getElementById('HEADER').scrollIntoView();
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    // if (!currentSession) { window.location.href = "/signin"; }
    this.setState({
      currentUser: currentSession
    });

    const script = document.createElement("script");

    script.src = "https://embed.clickmeeting.com/embed_conference.html?r=1712918233155993&w=1024&h=1000";
    script.async = true;
    this.refs["clickmeeting"].appendChild(script);


    // this.DataLoad();
  }


  getSegment = (url, index) => (
    url.replace(/^https?:\/\//, '').split('/')[index]
  )

  handleStop = () => {
    this.setState({
      vid_link: null,
      SliderPlaying: false
    });
  }

  handleStart = () => {
    const { Banners } = this.state;
    this.setState({
      vid_link: Banners.video_url,
      SliderPlaying: true
    });
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  }


  ext(url) {
    return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
  }

  render() {
    const { muted, vid_link, SliderPlaying, currentUser, Banners, Popular, inlineLoading, visible, videoUrl } = this.state;
    var videoTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const title = videoTitle.slice(0, 10);
    const bannerTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const Btitle = bannerTitle.slice(0, 20);
    const tagLine = Banners && Banners.tag_line != null ? Banners.tag_line : 'No Tag Line';
    const Tline = tagLine.slice(0, 20);
    //const vid_link = Banners && Banners.video_url != null ? Banners.video_url : 'No Video URL';

      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Webinar | Keion's Korner</title>
            </Helmet>

              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
              {/* slider */}

              <div className="webinar_header">
                <Container>
                  <div className="theshift_subtitle">SPEAKER <span>KORNER</span></div>
                  <h1 className="theshift_title">Webinar</h1>
                </Container>
              </div>

              <div ref="clickmeeting" id="clickmeeting">

              </div>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default Webinar;