import React, { Component } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import "react-modal-video/css/modal-video.min.css";
//Components List
import jsonFetch from '../common/api';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';


class L3Conferences extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        inlineLoading: false,
        Conferences: null,
        crushedFlag: false,
        video_id: null,
        videoTitle: null,
        tagLine: null,
        videoUrl: null,
        favCount: 0,
        videoId: null,
        currentUser: null,
      };
      this.openModal = this.openModal.bind(this);
      this.getSegment = this.getSegment.bind(this);
      this.markFav = this.markFav.bind(this);
      this.myRef = React.createRef();
  }

  async componentDidMount() {
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    this.setState({
      currentUser: currentSession
    });
    this.Dataload();
  }

  Dataload = async () => {
    this.setState({inlineLoading: true});
    const params = JSON.stringify({type: 'L3 Conferences'});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('loadAssets', { body: body, method: 'POST' });
    //console.log("l3 conference page", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      this.setState({
        Conferences: response.data,
      });
    }
    else{
      toast(response.message);
    }
  }

  openModal(vidId) {
    this.setState({
      isOpen: true,
      video_id: vidId
    });
  }

  playVideo(media) {
    //console.log('playVideo', media);
    this.setState({
      crushedFlag: true,
      videoId: media.id,
      videoUrl: media.video_url,
      videoTitle: media.title,
      tagLine: media.tag_line,
      favCount: media.fav_count,
    });

    this.scrollToMyRef();
  }

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop - 200);

  getSegment = (url, index) => (
    url.replace(/^https?:\/\//, '').split('/')[index]
  )

  async markFav(fav_id){
    //console.log('fav id', fav_id);
    const params = JSON.stringify({});
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('fav/' + fav_id, { body, method: 'POST' });
    if (response.success === 1) {
      toast(response.message);
      this.Dataload();
    } else {
      toast(response.message);
    }
  }

  mustLoggedIn = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete_popup">
            <h3>Please login to mark as favorite!</h3>
            <button type="button" onClick={onClose}>Cancel</button>
            <button
              type="button"
              onClick={() => {
                window.location.href = '/signin';
              }}
            >
              {'Login'}
            </button>
          </div>
        );
      }
    });
  }

  render() {
    const { currentUser, inlineLoading, isOpen, video_id, Conferences, crushedFlag, videoId, videoUrl, videoTitle, tagLine, favCount } = this.state;
      return (
        <React.Fragment>
          {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </div> : ''}
          <Helmet>
              <meta charSet="utf-8" />
              <title>L3 Conferences | Keion Henderson</title>
          </Helmet>

            {/* NavBar: src/components/common/NavBar */}
            <NavBar />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                <Container>
                  <div className="music_title">
                    <span>L3 Conferences</span><img src={IMAGES.eventsIcon} alt="music" />
                  </div>
                </Container>
              </div>
            </div>

            <Container>

              <div className="row top-padding text_color">
                { Conferences && Conferences != null && Conferences.length > 0 ? Conferences.map(function (item, index) {
                  var videoTitle = item.title;
                  const title = videoTitle.slice(0, 20);
                  const videoId = this.getSegment(item.video_url, 1);
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 popular_slick_slider" key={index.toString()}>
                      <div className="favorites_item">
                        <img src={item.img_url == null ? IMAGES.Trending1 :  base_url.baseImageUpload + item.img_url} alt="conference" />
                        { currentUser && currentUser != null ?
                          item.favorites && item.favorites.length ? <div className="heart_icon"> <i className="icofont-heart" /></div> : <div className="heart_icon">
                          <i className="icofont-heart fav_heart" onClick= {() => { this.markFav(item.id); }} style={{ cursor: "pointer" }} />
                        </div> : <div className="heart_icon">
                          <i className="icofont-heart fav_heart" onClick= {() => { this.mustLoggedIn();}} style={{ cursor: "pointer" }} />
                        </div> }
                        <a href="javascript:void(0)" onClick= {() => this.playVideo(item) } className="btn btn-secondary popular_slider_btn">
                            <span>{title}</span><i className="icofont-ui-play" />
                        </a>
                      </div>
                    </div>
                  );
                }, this) : 'No Conferences'}
              </div>

            </Container>

            <div className="crushed" id="crushed" ref={this.myRef}>
              {crushedFlag && crushedFlag === true ?
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {videoUrl && videoUrl != null ? <div className="video_player"><ReactPlayer url={videoUrl} playing controls volume width='100%'  pip /> </div> : <img src={IMAGES.jakesDp} />}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="it_crushed_me">
                    <h3>Continue <span>WATCHING</span></h3>
                    <h1>{videoTitle && videoTitle != null ? videoTitle : 'It Crushed Me'}</h1>
                    <h2>{tagLine && tagLine != null ? tagLine : 'TD Jakes Interviews'}</h2>
                  </div>
                </div>
              </div>
               : ''}
            </div>

            {/* Footer: src/components/common/Footer */}
            <Footer pageName="home" />
        </React.Fragment>
      );
  }
}

export default L3Conferences;
