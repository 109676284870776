import React, { Component } from "react";
import axios from "axios";
import Subscribe from "../components/common/Subscribe";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import NavBar from "../components/common/NavBar";
import GoogleMap from "../components/common/GoogleMap";
import Footer from "../components/common/Footer";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class ContactUS extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    constructor(props) {
        super(props);
        this.state = {
          additionalTransfrom: 0,
          name: "",
          email: "",
          subject: "",
          message: "",
          successMsg: ""
        };
    }

    handleForm = e => {
        let that = this;
        axios
            .post("https://formcarry.com/s/zER7_IhCyp5", this.state, {
                headers: { Accept: "application/json" }
            })
            .then(function(response) {
                document.getElementById("contactForm").reset();
                that.setState({
                    successMsg: "We received your submission"
                });
                document.getElementById("contactForm").reset();
            })
            .catch(function(error) {});
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });

    render() {
      const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          // so that we don't over-slide
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX / 100; // calculate the unit of transform for the slider
      }
      const { transform } = carouselState;
      return (
        <div className="custom-slider">
          <input
            type="range"
            value={Math.round(Math.abs(transform) / value)}
            defaultValue={0}
            max={
              (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
              value
            }
            onChange={e => {
              if (this.Carousel.isAnimationAllowed) {
                this.Carousel.isAnimationAllowed = false;
              }
              const nextTransform = e.target.value * value;
              const nextSlide = Math.round(nextTransform / carouselItemWidth);
              if (
                e.target.value == 0 &&
                this.state.additionalTransfrom === 150
              ) {
                this.Carousel.isAnimationAllowed = true;
                this.setState({ additionalTransfrom: 0 });
              }
              this.Carousel.setState({
                transform: -nextTransform, // padding 20px and 5 items.
                currentSlide: nextSlide
              });
            }}
            className="custom-slider__input"
          />
        </div>
      );
  };
        return (
            <React.Fragment>
                {/* NavBar: src/components/common/NavBar */}
                <NavBar pageName="contact" />
                <div>
                    {/* Start Page Title Area */}
                      <div className="d-table">
                          <div className="d-table-cell">
                              <div className="container">
                                  <div className="page-title-content">
                                    <Carousel
                                      ssr={false}
                                      ref={el => (this.Carousel = el)}
                                      partialVisbile={false}
                                      customButtonGroup={<CustomSlider />}
                                      itemClass="slider-image-item"
                                      responsive={responsive}
                                      containerClass="carousel-container-with-scrollbar"
                                      additionalTransfrom={-this.state.additionalTransfrom}
                                      beforeChange={nextSlide => {
                                        if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
                                          this.setState({ additionalTransfrom: 150 });
                                        }
                                        if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
                                          this.setState({ additionalTransfrom: 0 });
                                        }
                                      }}
                                    >
                                      <div class="image-container increase-size">
                                        <div class="image-container-text">
                                          <p>1</p>
                                        </div>
                                        <img
                                          draggable={false}
                                          style={{ width: "100%", height: "400px", cursor: "pointer" }}
                                          src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                                        />
                                      </div>
                                      <div class="increase-size">
                                        <div class="image-container-text">
                                          <p>2</p>
                                        </div>
                                        <img
                                          draggable={false}
                                          style={{ width: "100%", height: "400px", cursor: "pointer" }}
                                          src="https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                                        />
                                      </div>

                                      <div class="image-container increase-size">
                                        <div class="image-container-text">
                                          <p>3</p>
                                        </div>
                                        <img
                                          draggable={false}
                                          style={{ width: "100%", height: "400px", cursor: "pointer" }}
                                          src="https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                                        />
                                      </div>

                                      <div class="image-container increase-size">
                                        <div class="image-container-text">
                                          <p>4</p>
                                        </div>
                                        <img
                                          draggable={false}
                                          style={{ width: "100%", height: "400px", cursor: "pointer" }}
                                          src="https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                                        />
                                      </div>

                                      <div class="image-container increase-size">
                                        <div class="image-container-text">
                                          <p>5</p>
                                        </div>
                                        <img
                                          draggable={false}
                                          style={{ width: "100%", height: "400px", cursor: "pointer" }}
                                          src="https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                                        />
                                      </div>

                                      <div class="image-container increase-size">
                                        <div class="image-container-text">
                                          <p>6</p>
                                        </div>
                                        <img
                                          draggable={false}
                                          style={{ width: "100%", height: "400px", cursor: "pointer" }}
                                          src="https://images.unsplash.com/flagged/photo-1556091766-9b818bc73fad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1504&q=80"
                                        />
                                      </div>
                                    </Carousel>
                                  </div>
                              </div>
                          </div>
                      </div>
                    {/* End Page Title Area */}
                    {/* Start Contact Area */}
                    <section className="contact-area bg-gray ptb-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-phone" />
                                        </div>
                                        <div className="content">
                                            <h4>{this.props.phoneTitle}</h4>
                                            <p>
                                                <a href="callto:+021245522455">
                                                    {this.props.phoneNoOne}
                                                </a>
                                            </p>
                                            <p>
                                                <a href="callto:+000245522455">
                                                    {this.props.phoneNoTwo}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-envelope" />
                                        </div>
                                        <div className="content">
                                            <h4>{this.props.emailTitle}</h4>
                                            <p>
                                                <a
                                                    href={
                                                        "mailto:" +
                                                        this.props
                                                            .emailAddressOne +
                                                        ""
                                                    }
                                                >
                                                    {this.props.emailAddressOne}
                                                </a>
                                            </p>
                                            <p>
                                                <a
                                                    href={
                                                        "mailto:" +
                                                        this.props
                                                            .emailAddressTwo +
                                                        ""
                                                    }
                                                >
                                                    {this.props.emailAddressTwo}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-google-map" />
                                        </div>
                                        <div className="content">
                                            <h4>{this.props.locationTitle}</h4>
                                            <p>
                                                {
                                                    this.props
                                                        .locationAddressLineOne
                                                }
                                                <span>
                                                    {
                                                        this.props
                                                            .locationAddressLineTwo
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div id="map">
                                        {/* GoogleMap: src/components/common/GoogleMap */}
                                        <GoogleMap />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="contact-text">
                                        <h3>{this.props.contactTitle}</h3>
                                        <p>
                                            {this.props.contactDescriptionsOne}
                                        </p>
                                        <p>
                                            {this.props.contactDescriptionsTwo}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <ValidationForm
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="name"
                                                        id="name"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your email address"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="subject"
                                                        id="subject"
                                                        type="text"
                                                        //required
                                                        successMessage=""
                                                        errorMessage="Please enter your email subject"
                                                        className="form-control"
                                                        placeholder="Subject"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="message"
                                                        id="description"
                                                        multiline
                                                        placeholder="Your message"
                                                        className="form-control"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please write your message"
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                        <div className="clearfix" />
                                    </ValidationForm>
                                    {this.state.successMsg !== "" ? (
                                        <h3 className="contactMsg">
                                            {this.state.successMsg}
                                        </h3>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Start Contact Area */}
                </div>
                {/* Subscribe: src/components/common/Subscribe */}
                <Subscribe />
                {/* Footer: src/components/common/Footer */}
                <Footer pageName="contact" />
            </React.Fragment>
        );
    }
}

//Props Types
ContactUS.propTypes = {
    sectionName: PropTypes.string,

    phoneTitle: PropTypes.string,
    phoneNoOne: PropTypes.string,
    phoneNoTwo: PropTypes.string,
    emailTitle: PropTypes.string,
    emailAddressOne: PropTypes.string,
    emailAddressTwo: PropTypes.string,
    locationTitle: PropTypes.string,
    locationAddressLineOne: PropTypes.string,
    locationAddressLineTwo: PropTypes.string,

    contactTitle: PropTypes.string,
    contactDescriptionsOne: PropTypes.string,
    contactDescriptionsTwo: PropTypes.string
};
//Default Props
ContactUS.defaultProps = {
    sectionName: "CONTACT AXOLOT",

    phoneTitle: "Phone / Fax",
    phoneNoOne: "(+021) 245522455",
    phoneNoTwo: "(+021) 245522456",
    emailTitle: "E-mail",
    emailAddressOne: "info@Axolot.com",
    emailAddressTwo: "support@Axolot.com",
    locationTitle: "Location",
    locationAddressLineOne: "2750 Quadra Street , Park Area,",
    locationAddressLineTwo: "Victoria, Canada.",

    contactTitle: "Get in Touch..",
    contactDescriptionsOne:
        "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam tempus magna vel turpis pharetra dictum.",
    contactDescriptionsTwo:
        "Sed blandit tempus purus, sed sodales leo rutrum vel. Nam vulputate ipsum ac est congue, eget commodo magna lobortis."
};

export default ContactUS;
