export default {
  logo: require('../assets/images/logo.png'),
  footerLogo: require('../assets/images/logo.png'),
  searchIcon: require('../assets/images/search-icon.png'),
  bellIcon: require('../assets/images/bell-icon.png'),
  profilePic: require('../assets/images/avatar.png'),
  whySlide1: require('../assets/images/why-slide1.jpg'),
  whySlide2: require('../assets/images/why-slide2.jpg'),
  whySlide3: require('../assets/images/why-slide3.jpg'),
  popularSlider1: require('../assets/images/popular-slider1.png'),
  popularSlider2: require('../assets/images/popular-slider2.png'),
  popularSlider3: require('../assets/images/popular-slider3.png'),
  popularSlider4: require('../assets/images/popular-slider4.png'),
  Trending1: require('../assets/images/trending1.png'),
  Trending2: require('../assets/images/trending2.png'),
  Trending3: require('../assets/images/trending3.png'),
  Trending4: require('../assets/images/trending4.png'),
  jakesDp: require('../assets/images/jakes-dp.jpg'),
  arrow: require('../assets/images/arrow.png'),
  envelop: require('../assets/images/envelop.png'),
  fbIcon: require('../assets/images/fb-icon.png'),
  twitIcon: require('../assets/images/twit-icon.png'),
  instaIcon: require('../assets/images/insta-icon.png'),
  linkedIcon: require('../assets/images/linked-icon.png'),
  musicIcon: require('../assets/images/music-icon.png'),
  eventsIcon: require('../assets/images/events-icon.png'),
  albumPic: require('../assets/images/album-pic.png'),
  camera: require('../assets/images/camera.png'),
  music1: require('../assets/images/music1.png'),
  music2: require('../assets/images/music2.png'),
  music3: require('../assets/images/music3.png'),
  music4: require('../assets/images/music4.png'),
  music5: require('../assets/images/music5.png'),
  music6: require('../assets/images/music6.png'),
  music7: require('../assets/images/music7.png'),
  music8: require('../assets/images/music8.png'),
  Hat1: require('../assets/images/hat-1.jpg'),
  Large: require('../assets/images/large.jpg'),
  LCShortSleeve: require('../assets/images/LC-Short-Sleeve.jpg'),
  LighthouseLogo: require('../assets/images/Lighthouse-Logo.png'),
  PrayerShirt: require('../assets/images/Prayer-Shirt.png'),
  LighthouseLogoTee: require('../assets/images/Lighthouse-Logo-Tee.jpg'),
  EventsBanner: require('../assets/images/khprime_calendar_HQ.jpg'),
  BCLBanner: require('../assets/images/BCL-Banner.png'),
  NoVideo: require('../assets/images/novideo.png'),
  LocIcon: require('../assets/images/location-icon.png'),
  theShift: require('../assets/images/the-shift.png'),
  streamline: require('../assets/images/streamLine.png'),
  hdPi: require('../assets/images/hdpi.png'),
  cloud: require('../assets/images/cloud.png'),
  content: require('../assets/images/content.png'),
  theShiftCover: require('../assets/images/the-shift-cover.jpg'),
  theAuthor: require('../assets/images/keion-henderson-the-author.jpg')
};
