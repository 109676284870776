import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Page from "react-page-loading";

//Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS Style
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";

//Component Import
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/Home.Three";
import ContactUS from "./pages/ContactUs";
import ScrollUpBtn from "./components/common/ScrollUpBtn";

import SignUp from "./pages/SignUp";
import EditUser from "./pages/Edit-user";
import SignIn from "./pages/SignIn";
import MusicPlaylist from "./pages/MusicPlaylist";
import Subscription from "./pages/Subscription";
import Favorites from "./pages/Favorites";
import Events from "./pages/Events";
import PodCasts from "./pages/PodCasts";
import EventDetails from "./pages/EventDetails";
import Conferences from "./pages/Conferences";
import L3Conferences from "./pages/L3Conferences";
import Products from "./pages/Products";
// import Lists from "./pages/Lists";
import Cart from "./pages/Cart";
import ProductDetails from "./pages/ProductDetails";
import CheckoutPage from "./pages/CheckoutPage";
import Sermons from "./pages/Sermons";
import PopularOnLhc from "./pages/PopularOnLhc";
import PageNotFound from "./pages/404";
import ScrollToTop from "./components/common/ScrollToTop";
import RequestTrial from "./pages/RequestTrial";
import TheShift from "./pages/TheShift";
import Webinar from "./pages/Webinar";

class App extends Component {

  constructor() {
      super();
      this.state = {
        currentUser: null
      };
  }

  async componentDidMount(){
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    this.setState({ currentUser:  currentSession  });
  }
  render() {
    const { currentUser } = this.state;
    return (
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <ScrollToTop>
            <Switch>
              <Route
                  path="/contact"
                  component={ContactUS}
              />
              <Route
                  path="/home-three"
                  component={HomeThree}
              />
              <Route
                  path="/home-two"
                  component={HomeTwo}
              />
              <Route
                  path="/"
                  exact
                  component={!currentUser ? RequestTrial : HomeOne }
              />
              <Route
                  path="/signup"
                  exact
                  component={SignUp}
              />
              <Route
                  path="/signin"
                  exact
                  component={SignIn}
              />
              <Route
                  path="/music-playlist"
                  exact
                  component={MusicPlaylist}
              />
              <Route
                  path="/edit-user"
                  exact
                  component={EditUser}
              />
              <Route
                  path="/subscribe"
                  exact
                  component={Subscription}
              />
              <Route
                  path="/favorites"
                  exact
                  component={Favorites}
              />
              <Route
                  path="/events"
                  exact
                  component={Events}
              />
              <Route
                  path="/event-details/:id"
                  exact
                  component={EventDetails}
              />
              <Route
                  path="/podcasts"
                  exact
                  component={PodCasts}
              />
              <Route
                  path="/conferences"
                  exact
                  component={Conferences}
              />
              <Route
                  path="/l3-conferences"
                  exact
                  component={L3Conferences}
              />
              <Route
                  path="/products"
                  exact
                  component={Products}
              />
              <Route
                  path="/cart"
                  exact
                  component={Cart}
              />
              <Route
                  path="/product-details/:id"
                  exact
                  component={ProductDetails}
              />
              <Route
                  path="/checkout"
                  exact
                  component={CheckoutPage}
              />
              <Route
                  path="/sermons"
                  exact
                  component={Sermons}
              />
              <Route
                  path="/theshift"
                  exact
                  component={TheShift}
              />
              <Route
                  path="/webinar"
                  exact
                  component={Webinar}
              />
              <Route
                  path="/popular-lhc"
                  exact
                  component={PopularOnLhc}
              />
              <Route component={PageNotFound} />
            </Switch>
          </ScrollToTop>
        </Router>
    );
  }
}
export default App;
