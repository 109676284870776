import React, { Component } from "react";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container } from "react-bootstrap";
import { Dimmer, Loader } from 'semantic-ui-react';
import { toast, ToastContainer } from 'react-toastify';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import jsonFetch from '../../common/api';
import IMAGES from '../../common/images';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineLoading: false,
      fname: '',
      lname: '',
      email: '',
      phone: '',
      visible: false
    };

    this.handleFields = this.handleFields.bind(this);
    this.handleForm = this.handleForm.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  async handleForm (e) {
    this.setState({inlineLoading: true});
    const {
      fname,
      lname,
      email,
      phone
    } = this.state;

    const params = JSON.stringify({ fname, lname, email, phone });
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('subscribe', { body, method: 'POST' });
    //console.log('subscribe', response);
    this.setState({inlineLoading: false});
    if (response.success === 1) {
      this.setState({
        visible: false,
        fname: '',
        lname: '',
        email: '',
        phone: '',
      });
      toast(response.message);
    } else {
      this.setState({
        visible: false,
        fname: '',
        lname: '',
        email: '',
        phone: '',
      });
      toast(response.message);
    }

  }

  handleFields = e => this.setState({ [e.target.name]: e.target.value });

    render() {
      const {
        inlineLoading,
        visible,
        fname,
        lname,
        email,
        phone
       } = this.state;
        //Support loop start
        const supportLinks = this.props.supportsLinks.map((support, index) => (
            <li key={index}>
                <a href={support.Link}>
                    <i className="icofont-double-right" />
                    {support.Name}
                </a>
            </li>
        ));
        //Support loop END

        return (
            <React.Fragment>

            {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </div> : ''}

            <Modal
              visible={visible}
              title="Subscribe"
              onCancel={this.handleCancel}
              footer={null}
            >
              <ValidationForm
                  id="contactForm"
                  onSubmit={(e, formData, inputs) => {
                      e.preventDefault();
                      this.handleForm(formData);
                  }}
              >
                  <div className="row subscribe_form">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <TextInput
                                name="fname"
                                value={fname}
                                id="fname"
                                type="text"
                                required
                                successMessage=""
                                errorMessage="Please enter first name"
                                className="form-control input_field"
                                placeholder="First Name"
                                autoComplete="off"
                                autoFocus={true}
                                onChange={e => {
                        					let value = e.target.value;

                        					value = value.replace(/[^A-Za-z]/gi, "");

                        					this.setState({
                        						fname: value
                        					});
                        				}}
                            />
                            <div className="help-block with-errors" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <TextInput
                                name="lname"
                                value={lname}
                                id="lname"
                                type="text"
                                required
                                successMessage=""
                                errorMessage="Please enter last name"
                                className="form-control input_field"
                                placeholder="Last Name"
                                autoComplete="off"
                                onChange={e => {
                        					let value = e.target.value;

                        					value = value.replace(/[^A-Za-z]/gi, "");

                        					this.setState({
                        						lname: value
                        					});
                        				}}
                            />
                            <div className="help-block with-errors" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <TextInput
                                name="email"
                                value={email}
                                id="email"
                                type="email"
                                required
                                successMessage=""
                                validator={validator.isEmail}
                                errorMessage={{validator:"Please enter a valid email"}}
                                className="form-control input_field"
                                placeholder="Email"
                                autoComplete="off"
                                onChange={
                                    this.handleFields
                                }
                            />
                            <div className="help-block with-errors" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <TextInput
                                name="phone"
                                value={phone}
                                pattern="[0-9]*"
                                maxLength = "10"
                                id="phone"
                                type="text"
                                required
                                successMessage=""
                                errorMessage="Please enter phone"
                                className="form-control input_field"
                                placeholder="Phone"
                                autoComplete="off"
                                onChange={
                                    this.handleFields
                                }
                            />
                            <div className="help-block with-errors" />
                        </div>
                    </div>

                  </div>

                  <div className="submit_btn">
                    <div className="form-group">
                      <button className="btn btn-secondary">
                          SUBSCRIBE
                      </button>
                    </div>
                  </div>

                  <div className="clearfix" />
              </ValidationForm>
            </Modal>


                <footer className="footer-area" id="footer">
                  <div className="subscribe_prime" onClick={this.showModal}>
                    <Container className="resp_no_padding">
                      <div className="row" style={{ paddingBottom: '0px' }}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="subscribe_prime_text">
                            SUBSCRIBE TO KEION'S KORNER
                          </div>
                        </div>
                      </div>
                    </Container>
                    <div className="sub_mail">
                      <img src={IMAGES.envelop} alt="envelop" />
                    </div>
                  </div>

                  <div className="container">
                    <div className="row footer_menu">
                      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        {this.props.pageName === "home" ? (
                            <React.Fragment>
                                <Link
                                    to="/"
                                    offset={-200}
                                    duration={800}
                                    className="navbar-brand"
                                >
                                    <img src={IMAGES.logo} alt="logo" />
                                </Link>
                            </React.Fragment>
                        ) : (
                            <LinkContainer exact to="/">
                                <Navbar.Brand className="navbar-brand logo">
                                    <img src={IMAGES.logo} alt="logo" />
                                </Navbar.Brand>
                            </LinkContainer>
                        )}
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 resp_no_padding">
                        <div className="footer_nav">
                          <ul>
                            <li><Link to="/popular-lhc">POPULAR ON LHC </Link> <span className="vertical_line">|</span></li>
                            <li><Link to="/sermons">SERMONS</Link> <span className="vertical_line">|</span></li>
                            <li><Link to="/conferences">CONFERENCES</Link><span className="vertical_line">|</span></li>
                            <li><Link to="/events">EVENTS</Link><span className="vertical_line">|</span></li>
                            <li><Link to="/theshift">THE SHIFT</Link><span className="vertical_line">|</span></li>
                            <li><Link to="/podcasts">PODCASTS</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="copyright-area">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                  <p>{this.props.copyRightText}</p>
                              </div>
                              {/*<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                  <ul>
                                      {this.props.fbLink && (
                                          <li>
                                            <Link to={this.props.fbLink}>
                                              <img src={IMAGES.fbIcon} alt="facebook" />
                                            </Link>
                                          </li>
                                      )}

                                      {this.props.twitterlLink && (
                                          <li>
                                            <Link to={this.props.fbLink}>
                                              <img src={IMAGES.twitIcon} alt="twitter" />
                                            </Link>
                                          </li>
                                      )}

                                      {this.props.instagramlLink && (
                                          <li>
                                            <Link to={this.props.fbLink}>
                                              <img src={IMAGES.instaIcon} alt="instagram" />
                                            </Link>
                                          </li>
                                      )}

                                      {this.props.linkedinlLink && (
                                          <li>
                                            <Link to={this.props.fbLink}>
                                              <img src={IMAGES.linkedIcon} alt="linkedIn" />
                                            </Link>
                                          </li>
                                      )}
                                  </ul>
                              </div>*/}
                          </div>
                      </div>
                  </div>
                </footer>
                <ToastContainer />



            </React.Fragment>
        );
    }
}

//Props Types
Footer.propTypes = {
    siteDescription: PropTypes.string,
    btnText: PropTypes.string,
    btnLink: PropTypes.string,

    usefullLinksTitle: PropTypes.string,

    supportTitle: PropTypes.string,
    supportsLinks: PropTypes.array,

    contactInfoTitle: PropTypes.string,
    contactInfoText: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,

    fbLink: PropTypes.string,
    twitterlLink: PropTypes.string,
    instagramlLink: PropTypes.string,
    linkedinlLink: PropTypes.string,
    vimeolLink: PropTypes.string
};

//Default Props
Footer.defaultProps = {
    siteDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. sed do eiusmod tempor incididunt ut, tempor incididunt ut.",
    btnText: "Read More",
    btnLink: "#",

    usefullLinksTitle: "Useful Links",

    supportTitle: "Support",
    supportsLinks: [
        {
            Name: "Career",
            Link: "#"
        },
        {
            Name: "Support",
            Link: "#"
        },
        {
            Name: "Resource",
            Link: "#"
        },
        {
            Name: "Strategy",
            Link: "#"
        },
        {
            Name: "FAQ",
            Link: "#"
        },
        {
            Name: "Contact",
            Link: "#"
        }
    ],

    contactInfoTitle: "Contact Info",
    contactInfoText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    address: "2750 Quadra Street , Park Area, Victoria, Canada.",
    phone: "+(00) 01245687",
    email: "support@axolot.com",

    copyRightText: "© KEION'S KORNER  - ALL RIGHTS RESERVED 2020",
    fbLink: "#",
    twitterlLink: "#",
    instagramlLink: "#",
    linkedinlLink: "#",
    vimeolLink: "#"
};
export default Footer;
