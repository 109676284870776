import React, { Component } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsonFetch from '../../common/api';
import base_url from '../../common/baseUrl';
import IMAGES from '../../common/images';

class VideosSliderComp extends Component{
  constructor(props) {
      super(props);
      this.state = props.vidData;
      this.state = {
        currentUser: null,
        videoUrl: null,
        visible: false,
      }
      this.markFav = this.markFav.bind(this);
      this.playVideo = this.playVideo.bind(this);
  }

  hide() {
    this.setState({
      visible: false,
    });

    this.props.startPrev();

    setTimeout(()=> {
      this.setState(()=> ({ videoUrl: null }))
    }, 700);
  }

  async componentDidMount(){
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    this.setState({
      currentUser: currentSession,
    });
  }

  async playVideo(media) {
    this.props.stopPrev();

    const { currentUser } = this.state;

    if (media.is_paid === 1 && currentUser && currentUser != null) {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });

    } else if (media.is_paid === 1 && currentUser === null) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete_popup">
              <h3>Please login to watch this video, it's Paid!</h3>
              <button type="button" onClick={onClose}>Cancel</button>
              <button
                type="button"
                onClick={() => {
                  window.location.href = '/signin';
                }}
              >
                {'Login'}
              </button>
            </div>
          );
        }
      });
    } else {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });
      //console.log('playcount response', response);
    }

  }

  async markFav(fav_id){
    const params = JSON.stringify({});
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('fav/' + fav_id, { body, method: 'POST' });
    if (response.success === 1) {
      toast(response.message);
      this.props.DataLoadFunc();
    } else {
      toast(response.message);
    }
  }

  mustLoggedIn = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete_popup">
            <h3>Please login to mark as favorite!</h3>
            <button type="button" onClick={onClose}>Cancel</button>
            <button
              type="button"
              onClick={() => {
                window.location.href = '/signin';
              }}
            >
              {'Login'}
            </button>
          </div>
        );
      }
    });
  }

  truncate(str, no_words) {
    return str.split(" ").splice(0,no_words).join(" ");
  }

  render(){
    const { currentUser, visible, videoUrl } = this.state;
    const vidData = this.props.vidData;

    var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };

    return(
      <div>

        <Rodal className="rodal_video_modal" duration={1000} closeOnEsc={true} visible={visible} onClose={this.hide.bind(this)}>
          <ReactPlayer url={videoUrl} loop={true} playing controls={1} volume={1} width='100%' />
        </Rodal>

        <div className="popular_slick_slider">
          <Slider {...settings}>
            { !vidData ? null : vidData.map(function (item, index) {
              //console.log('item', item);
              //var videoTitle = item.title;
              //const title = videoTitle.slice(0, 20);
              const title = this.truncate(item.title, 4);
              return (
                <div key={index.toString()}>
                  <div>
                    { currentUser && currentUser != null ?
                      item.favorites && item.favorites.length ? <div className="heart_icon"> <i className="icofont-heart" /></div> : <div className="heart_icon">
                      <i className="icofont-heart fav_heart" onClick= {() => { this.markFav(item.id); }} />
                    </div> : <div className="heart_icon">
                      <i className="icofont-heart fav_heart" onClick= {() => { this.mustLoggedIn();}} />
                    </div> }
                  </div>

                  <div className="slider_item" onClick= {() => this.playVideo(item) }>
                    <img src={!item.img_url && item.img_url == null ? IMAGES.popularSlider1 :  base_url.baseImageUpload + item.img_url} alt="popular1" />

                    <div className="data_on_hover">
                      {item.category.title != null ? item.category.title : '' }
                    </div>

                    <a href="javascript:void(0)" className="btn btn-secondary popular_slider_btn">
                      <span>{title}</span><i className="icofont-ui-play" />
                    </a>
                  </div>
                </div>
              );
            }, this)}
          </Slider>
        </div>

      </div>
    );
  }
}
export default VideosSliderComp;
