import React, { Component } from "react";
import moment from 'moment';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import "react-modal-video/css/modal-video.min.css";
//Components List
import jsonFetch from '../../common/api';
import base_url from '../../common/baseUrl';
import IMAGES from '../../common/images';

class EventPageComp extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        inlineLoading: false,
        videoUrl: null,
        visible: false,
      };
      this.markFav = this.markFav.bind(this);
      this.playVideo = this.playVideo.bind(this);
  }

  async markFav(fav_id){
    //console.log('fav id', fav_id);
    const params = JSON.stringify({});
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('fav/' + fav_id, { body, method: 'POST' });
    if (response.success === 1) {
      toast(response.message);
      setTimeout(
        function () {
          window.location.reload();
        },
        3000
      );
    } else {
      toast(response.message);
    }
  }

  async playVideo(media) {
    //console.log('media', media);
    const { currentUser } = this.state;

    if (media.is_paid === 1 && currentUser && currentUser != null) {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });

    } else if (media.is_paid === 1 && currentUser === null) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete_popup">
              <h3>Please loggedIn to watch this video, it's Paid!</h3>
              <button type="button" onClick={onClose}>Cancel</button>
              <button
                type="button"
                onClick={() => {
                  window.location.href = '/signin';
                }}
              >
                {'Login'}
              </button>
            </div>
          );
        }
      });
    } else {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });
      //console.log('playcount response', response);
    }

  }

  hide() {
    this.setState({
      visible: false,
    });

    setTimeout(()=> {
      this.setState(()=> ({ videoUrl: null }))
    }, 700);
  }

  mustLoggedIn = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete_popup">
            <h3>Please login to mark as favorite!</h3>
            <button type="button" onClick={onClose}>Cancel</button>
            <button
              type="button"
              onClick={() => {
                window.location.href = '/signin';
              }}
            >
              {'Login'}
            </button>
          </div>
        );
      }
    });
  }

  truncate(str, no_words) {
    return str.split(" ").splice(0,no_words).join(" ");
  }


  render() {
    const { inlineLoading, visible, videoUrl } = this.state;
    const EventsData = this.props.EventsData;
      return (
        <React.Fragment>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Events | Keion Henderson</title>
          </Helmet>

          <Rodal duration={1000} closeOnEsc={true} visible={visible} onClose={this.hide.bind(this)}>
            <ReactPlayer url={videoUrl} loop={true} playing controls volume={1} width='100%'  />
          </Rodal>

            <div className="row">
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
            </div>
            <Container>
              <div className="section_title"><span>{EventsData.monthName != null ? EventsData.monthName : ''} {EventsData.currentYear != null ? EventsData.currentYear : ''}</span></div>
              <div className="row text_color">
                { EventsData.data && EventsData.data != null && EventsData.data.length > 0 ? EventsData.data.map(function (item, index) {
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                    ];

                    var eventDetails = item.details;
                    const details = eventDetails.slice(0, 150);

                    const momentdate = moment(item.event_date).format('YYYY-MM-DD');
                    const now = moment().format('YYYY-MM-DD');

                    const date = new Date(item.event_date);
                    const event_month = monthNames[date.getMonth()];
                    var event_day = date.getUTCDate();
                    //var videoTitle = item.title;
                    //const title = videoTitle.slice(0, 20);
                    const title = this.truncate(item.title, 4);
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 events_page" key={index.toString()}>
                          <a href={'/event-details/' + item.id}>
                            <div className="latest_events">
                              { now > momentdate ? null : <div className="event_header">
                                <div className="event_red_side">{event_month}</div>
                                <div className="event_black_side">{event_day}</div>
                              </div> }
                              <div className="event_content">
                                <h3>{item.title}</h3>
                                <p>{details}</p>
                                <div className="arrow_view_more">
                                  <img src={IMAGES.arrow} alt="arrow" />
                                  <span>
                                    VIEW MORE
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                      </div>
                    );
                }, this) : <div className="no_data">{'No Events'}</div>}
              </div>

            </Container>

        </React.Fragment>
      );
  }
}
export default EventPageComp;
