import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Form, Input } from 'semantic-ui-react';
import jsonFetch from '../common/api';
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';
import { addToCart } from '../components/actions/cartActions'

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class ProductDetail extends Component {
  constructor() {
      super();
      this.state = {
        inlineLoading: false,
        product: null
      };
  }

  async componentDidMount(){

    const currentUser = await JSON.parse(localStorage.getItem("session"));
    if (!currentUser) { window.location.href = "/signin"; }
    const item = this.props.match.params.id;
    // console.log(item);
    // const existingEntries = this.props.items;
    if (item) {
      const itemSelect = this.props.items.find(record=> item == record.id);
      // console.log(itemSelect);
      this.setState({ product: itemSelect });
    }
    // this.setState({inlineLoading: true});
    // this.setState({inlineLoading: false});
  }

  handleClick = (id)=> {
    // this.setState({inlineLoading: true});
    this.props.addToCart(id);
    toast('Item added to Cart');
    setTimeout(
      function () {
        window.location.href = "/cart";
      },
      3000
    );
     console.log("id: ",id);
   }

  render() {
    const { inlineLoading, product } = this.state;
    console.log(product ? product.title : '');
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Product Details | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}

              <div className="primary_bg_section">
                <Container>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                      <div className="music_title">
                        <span>Product Details</span>
                      </div>
                  </div>
                </div>
                </Container>
              </div>

              <Container>
                <div className="row" style={{ marginTop: '100px' }}>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="product_item_image">
                      <img src={product ? product.img : ''} alt="product"/>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="product_item_details">
                      <h2 className="text_color">{product ? product.title : ''}</h2>
                      <div className="item_price">Price: <span>${product ? product.price : ''}</span></div>
                      <div className="item_desc">{product ? product.desc : ''}</div>
                      <div className="addToCart">
                        <a className="btn btn-secondary cartbtn" style={{ width: '50%' }} onClick={()=>{this.handleClick(product ? product.id : '')}}><i class="icofont-cart icon" />ADD TO CART</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

const mapStateToProps = (state)=>{
    return {
      items: state.items
    }
  }
  const mapDispatchToProps= (dispatch)=>{

      return {
          addToCart: (id)=>{dispatch(addToCart(id))}
      }
  }


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
