import React, { Component } from "react";
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import jsonFetch from '../common/api';
import IMAGES from '../common/images';
import PreloginVideoSlider from '../components/common/PreloginVideoSlider';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import TrialForm from "../components/common/TrialForm";
//Components List
import NavBarPreLogin from "../components/common/NavBarPreLogin";
import Footer from "../components/common/Footer";

class RequestTrial extends Component {
  constructor() {
      super();
      this.state = {
        currentUser: null,
        inlineLoading: false,
        Banners: null,
        Popular: null,
        visible: false,
        vid_link: null,
        SliderPlaying: true,
        muted: false,
      };
  }

  DataLoad = async () =>{
    this.setState({inlineLoading: true});
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('landing', { body: body, method: 'POST' });
    console.log("landing", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      var myArray = response.data.banners;
      var BannerItem = myArray[(Math.random()*myArray.length)|0];

      this.setState({
        vid_link: BannerItem.video_url
      });

      this.setState({
        Banners: BannerItem,
        Popular: response.data.popular,
      });
    }
    else{
      toast(response.message);
    }
  }

  async componentDidMount(){
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    // if (currentSession) { window.location.href = "/"; }
    this.setState({
      currentUser: currentSession
    });
    this.DataLoad();
  }

  DataLoadFunc = async () =>{
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('landing', { body: body, method: 'POST' });
    //console.log("landing", response.data);
    if(response.success === 1)
    {
      var myArray = response.data.banners;
      var BannerItem = myArray[(Math.random()*myArray.length)|0];

      this.setState({
        vid_link: BannerItem.video_url
      });

      this.setState({
        Banners: BannerItem,
        Popular: response.data.popular
      });
    }
    else{
      toast(response.message);
    }
  }

  getSegment = (url, index) => (
    url.replace(/^https?:\/\//, '').split('/')[index]
  )

  handleStop = () => {
    this.setState({
      vid_link: null,
      SliderPlaying: false
    });
  }

  handleStart = () => {
    const { Banners } = this.state;
    this.setState({
      vid_link: Banners.video_url,
      SliderPlaying: true
    });
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  }


  ext(url) {
    return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
  }

  render() {
    const { muted, vid_link, SliderPlaying, currentUser, Banners, Popular, inlineLoading, visible, videoUrl } = this.state;
    var videoTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const title = videoTitle.slice(0, 10);
    const bannerTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const Btitle = bannerTitle.slice(0, 20);
    const tagLine = Banners && Banners.tag_line != null ? Banners.tag_line : 'No Tag Line';
    const Tline = tagLine.slice(0, 20);
    //const vid_link = Banners && Banners.video_url != null ? Banners.video_url : 'No Video URL';

      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Request a Free Trial | Keion's Korner</title>
            </Helmet>

              {/* NavBar: src/components/common/NavBar */}
              <NavBarPreLogin />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
              {/* slider */}

              <div className="prelogin_bg">
                
                <div className="title_two">Access Premium Content <span>FREE</span> for <span>60 days!</span></div>
                <a href="#trial" className="btn btn-secondary header_btn"><i class="icofont-long-arrow-right icon" />START NOW</a>
              </div>

              <Container>

                <div className="section_title" style={{ textAlign: 'center'}}>Keion's Korner <span>Premium Content</span></div>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prelogin_iconbox">
                      <img src={IMAGES.streamline} />
                      <h3>Webinars</h3>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prelogin_iconbox">
                      <img src={IMAGES.hdPi} />
                      <h3>Music</h3>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prelogin_iconbox">
                      <img src={IMAGES.cloud} />
                      <h3>Podcasts</h3>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prelogin_iconbox">
                      <img src={IMAGES.content} />
                      <h3>Conferences</h3>
                    </div>
                  </div>
                </div>

                <div className="section_title" style={{ textAlign: 'center'}}>Preview from <span>THE KORNER</span></div>

                <PreloginVideoSlider stopPrev={this.handleStop} startPrev={this.handleStart} vidData={Popular} DataLoadFunc={this.DataLoadFunc} />
                <div id="trial"></div>
                <div className="prelogin_taste">WANT A TASTE OF KEION’S KORNER ?</div>
                <div className="section_title_prelogin" style={{ textAlign: 'center'}}>Request <span>A FREE TRIAL</span></div>

                <TrialForm />

              </Container>
              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default RequestTrial;
