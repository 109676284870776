import React from 'react'

const PodCastShowPlaylists = (props) => {
  
  const pointerStyles = { cursor: 'pointer' }

  return (
    <React.Fragment>
      <div className="album">
        <div className="album-playlist">
          <table className="table table-dark custom_table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Play</th>
                <th scope="col">TITLE</th>
                <th scope="col"><i className="icofont-ui-clock" /></th>

              </tr>
            </thead>
            <tbody>
              {props.selectedAlbum.items.map((song, index) => (
                <tr key={`songname-${index}`} className={"playlist-song" + (props.playlistIsPlaying === true ? 'show' : 'hidden')} onClick={() => props.updatePlaylist(props.selectedAlbum.items, index)}>
                  <td>{index+1}</td>
                  <td scope="row">{(JSON.stringify(props.playlist) === JSON.stringify(props.selectedAlbum.items.map(song => song.url)) && props.playlistIsPlaying && props.currentSongIndex === index)
                    ? (<i className="fa fa-pause" style={pointerStyles} />)
                    : (<i className="fa fa-play" style={{ paddingLeft: '3px', ...pointerStyles }} />)}</td>
                  <td>{song.title}</td>
                  <td>{song.duration}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PodCastShowPlaylists;
