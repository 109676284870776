import React, { Component } from "react";
import { connect } from 'react-redux';
import { NavLink, Link } from "react-router-dom";
import Pagination from 'react-js-pagination';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Form, Input } from 'semantic-ui-react';
import jsonFetch from '../common/api';
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';
import { addToCart } from '../components/actions/cartActions'

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class Products extends Component {
  constructor() {
      super();
      this.state = {
        inlineLoading: false,
        Products: null,
        AllProductList: [],
        activePage: 1,
      };
      this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {
    const currentUser = await JSON.parse(localStorage.getItem("session"));
    if (!currentUser) { window.location.href = "/signin"; }
    // localStorage.clear();
    // this.Dataload(1);
  }

  Dataload = async (pageNumber) => {
    this.setState({inlineLoading: true});
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('getProducts', { body: body, method: 'POST' });
    //console.log("getProducts ", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      this.setState({
        AllProductList: response.data,
        //Products: response.data.data,
      });

      let allProducts = [];
      response.data.data.map(function(prod, index){
        allProducts.push({
          id: prod.id,
          name: prod.name,
          price: prod.price,
          image: !prod.image ? IMAGES.Hat1 : base_url.baseImageUpload + prod.image })
    }, this);

    this.setState({
      Products: allProducts
    });

    }
    else{
      toast(response.message);
    }
  }

  handleClick = (id)=> {
    // this.setState({inlineLoading: true});
    this.props.addToCart(id);
    toast('Item added to Cart');
    setTimeout(
      function () {
        window.location.href = "/cart";
      },
      3000
    );
     console.log("id: ",id);
   }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.Dataload(pageNumber);
  }

  render() {
    let itemList = this.props.items.map(item=>{
        return(
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" key={item.id}>
              <div className="plist_item">
                  <div className="item_desc">
                    <div className="item_price">Price: <span>${item.price}</span></div>
                    <a href={'/product-details/' + item.id}><img src={item.img} /></a>
                    <div className="desc">{item.desc.substr(0,50)+' ...'}</div>
                  </div>
                  <div className="addToCart">
                    <a className="btn btn-secondary cartbtn" onClick={()=>{this.handleClick(item.id)}}><i class="icofont-cart icon" />ADD TO CART</a>
                  </div>
              </div>
           </div>
        )
    })

    const { inlineLoading, Products, AllProductList, activePage } = this.state;
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}

              <div className="primary_bg_section">
                <Container>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                      <div className="music_title">
                        <span>Products</span><img src={IMAGES.eventsIcon} alt="music" />
                      </div>
                  </div>
                </div>
                </Container>
              </div>

              <Container>
                <div className="row top-padding text_color">
                  {itemList}
                </div>
              </Container>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

const mapStateToProps = (state)=>{
    return {
      items: state.items
    }
  }
const mapDispatchToProps= (dispatch)=>{

    return {
        addToCart: (id)=>{dispatch(addToCart(id))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products)
