import React, { Component } from "react";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ReactPlayer from 'react-player';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-modal-video/css/modal-video.min.css";
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import jsonFetch from '../common/api';
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class Favorite extends Component {
  constructor() {
      super();
      this.state = {
          Banners: null,
          Favs: null,
          videoUrl: null,
          visible: false,
      };
      this.markFav = this.markFav.bind(this);
      this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.Dataload();
  }

  Dataload = async () => {
    this.setState({inlineLoading: true});
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('getFavs', { body: body, method: 'POST' });
    //console.log("getFavs", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      this.setState({
        Favs: response.data.favs,
      });
    }
    else{
      toast(response.message);
    }
  }

  async markFav(fav_id){
    //console.log('fav id', fav_id);
    const params = JSON.stringify({});
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('fav/' + fav_id, { body, method: 'POST' });
    if (response.success === 1) {
      toast(response.message);
      setTimeout(
        function () {
          window.location.reload();
        },
        3000
      );
    } else {
      toast(response.message);
    }
  }

  deleteFavorite = async (fav_id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete_popup">
            <h1>Are you sure?</h1>
            <h3>You want to marked Unfavorite?</h3>
            <button type="button" onClick={onClose}>No</button>
            <button
              type="button"
              onClick={async () => {
                const params = JSON.stringify({});
                const body = JSON.stringify({ paramsArray: params });
                const response = await jsonFetch('delfav/' + fav_id, { body, method: 'POST' });
                if (response.success === 1) {
                  toast(response.message);
                  setTimeout(
                    function () {
                      window.location.reload();
                    },
                    1000
                  );
                } else {
                  toast(response.message);
                }
              }}
            >
              {'Yes'}
            </button>
          </div>
        );
      }
    });
  }

  async playVideo(media) {
    //console.log('media', media);
    const { currentUser } = this.state;

    if (media.asset.is_paid === 1 && currentUser && currentUser != null) {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });

    } else if (media.asset.is_paid === 1 && currentUser === null) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete_popup">
              <h3>Please loggedIn to watch this video, it's Paid!</h3>
              <button type="button" onClick={onClose}>Cancel</button>
              <button
                type="button"
                onClick={() => {
                  window.location.href = '/signin';
                }}
              >
                {'Login'}
              </button>
            </div>
          );
        }
      });
    } else {
      this.setState({
        visible: true,
        videoUrl: media.asset.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.asset.id, { body, method: 'POST' });
      //console.log('playcount response', response);
    }

  }

  hide() {
    this.setState({
      visible: false,
    });

    setTimeout(()=> {
      this.setState(()=> ({ videoUrl: null }))
    }, 700);
  }

  truncate(str, no_words) {
    return str.split(" ").splice(0,no_words).join(" ");
  }

  render() {
    const { Favs, inlineLoading, visible, videoUrl } = this.state;
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Favorites | Keion Henderson</title>
            </Helmet>

            <Rodal duration={1000} closeOnEsc={true} visible={visible} onClose={this.hide.bind(this)}>
              <ReactPlayer url={videoUrl} loop={true} playing controls volume={1} width='100%'  />
            </Rodal>

              <NavBar />

              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                  <Container>
                    <div className="music_title">
                      <span>Favorites</span><img src={IMAGES.eventsIcon} alt="music" />
                    </div>
                  </Container>
                </div>
              </div>

              <Container>

                  <div className="row top-padding text_color">
                    { Favs && Favs != null && Favs.length > 0 ? Favs.map(function (item, index) {
                      if (item.asset && item.asset != null) {
                        const title = this.truncate(item.asset.title, 4);
                        //var videoTitle = item.asset.title;
                        //const title = videoTitle.slice(0, 20);
                        return (
                          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 favorites_item" key={index.toString()}>

                            <div>
                              <div className="delete_icon">
                                <i className="icofont-trash" onClick= {() => { this.deleteFavorite(item.asset.id); }} style={{ cursor: "pointer" }} />
                              </div>
                            </div>

                            <div className="popular_slick_slider" onClick= {() => this.playVideo(item) }>
                              <img src={item.asset != null ? base_url.baseImageUpload + item.asset.img_url : IMAGES.popularSlider1 } alt="popular1" />

                              <div className="data_on_hover">
                                {item.asset.category.title != null ? item.asset.category.title : 'No Category' }
                              </div>

                              <a href="javascript:void(0)" className="btn btn-secondary popular_slider_btn">
                                <span>{item.asset != null ? title : 'Play'}</span><i className="icofont-ui-play" />
                              </a>
                            </div>
                          </div>
                        );
                      }
                    }, this) : <div className="no_data">{'No Favorites'}</div>}
                  </div>

                </Container>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default Favorite;
