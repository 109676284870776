import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      pixelCrop: null,
      crop: {
        x: 10,
        y: 10,
        width: 50,
        aspect: 1 / 1
      }
    };
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => this.setState({ src: reader.result }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  onImageLoaded = (image) => {
    this.setState({ image });
    //console.log('onImageLoaded', image);
  }

  onCropComplete = (crop, pixelCrop) => {
    //console.log('onCropComplete', pixelCrop);
    this.setState({ pixelCrop });
  }

  onCropChange = (crop, pixelCrop) => {
    //console.log('onCropChange', pixelCrop);
    this.setState({ crop });
  }

  getCroppedImg(fileName) {
    const { image, pixelCrop } = this.state;
    if (image === undefined) {
      return null;
    }
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });
  }

  render() {
    const { src, crop } = this.state;
    return (
      <div className="App">
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            keepSelection="true"
          />
        )}
        <div>
          <input type="file" onChange={this.onSelectFile} />
        </div>
      </div>
    );
  }
}
export default ImageCropper;
