import React, { Component } from "react";
import {Elements, StripeProvider, CardElement} from 'react-stripe-elements';
import SignupForm from '../components/common/SignupForm';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-modal-video/css/modal-video.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import jsonFetch from '../common/api';
//import ImageCropper from '../common/ImageCropper';
import IMAGES from '../common/images';

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class SignUp extends Component {
  componentDidMount() {
      window.scrollTo(0, 0);
  }

  constructor(props) {
      super(props);
      this.state = {
      fname: '',
      lname: '',
      email: '',
      password: '',
      confirm_password: '',
      inlineLoading: false,
    };
    this.handleFields = this.handleFields.bind(this);
    this.handleForm = this.handleForm.bind(this);
    //this.imageCropper = React.createRef();
  }

    async handleForm (e) {
      this.setState({inlineLoading: true});
      let {token} = await this.props.stripe.createToken({name: "Name"});
      //console.log(token);
      const {
        fname,
        lname,
        email,
        password,
        confirm_password
      } = this.state;
      //console.log('input values', this.state);
      if (password !== confirm_password) {
        alert('Passwords dont match!!!');
    } else {
      const params = JSON.stringify({
        fname,
        lname,
        email,
        password
      });

      //const croppedImg = await this.imageCropper.current.getCroppedImg('profile_pic');
      const formData = new FormData();
      //formData.append('img', croppedImg);
      formData.append('paramsArray', params);
      const response = await jsonFetch('signup', { body: formData, method: 'POST' }, true);
      this.setState({inlineLoading: false});
      //console.log('signup response', response);
      if (response.success === 1) {
        localStorage.setItem('session', JSON.stringify(response.data));
        localStorage.setItem('access_token', JSON.stringify(response.token));
        window.location.href = '/';

      } else {
        toast(response.message);
        alert(response.message);
      }
    }
  };

  handleFields = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {
      fname,
      lname,
      email,
      password,
      confirm_password,
      inlineLoading
    } = this.state;
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SignUp | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />

              <div className="page_bg">
              <StripeProvider apiKey="pk_test_5K1uUmTuynX6nJYHhC44NMo2">
                <div className="example">
                  <Elements>
                    <SignupForm />
                  </Elements>
                </div>
              </StripeProvider>
              </div>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default SignUp;
