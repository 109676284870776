import React, {Component} from 'react';
import { Button, Icon, Upload } from 'antd';
import { Dimmer, Loader } from 'semantic-ui-react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import jsonFetch from '../../common/api';
import ImageCropper from '../../common/ImageCropper';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import IMAGES from '../../common/images';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        fname: '',
        lname: '',
        email: '',
        password: '',
        confirm_password: '',
        inlineLoading: false,
        img: null
    };
    this.handleFields = this.handleFields.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.imageCropper = React.createRef();
    this.onAttachmentsChange = this.onAttachmentsChange.bind(this);
  }

  async handleForm (e) {
    this.setState({inlineLoading: true});
    let {token} = await this.props.stripe.createToken({name: "Name"});
    if (!token) {
      toast('Payment Failed, Please try again');
      return false
    }

    const {
      fname,
      lname,
      email,
      password,
      img
    } = this.state;

    const formData = new FormData();
    if (this.state.img != null) {
      for (let i = 0; i < this.state.img.length; i++) {
        formData.append('img', this.state.img[i]);
      }
    }

    const params = JSON.stringify({
      fname,
      lname,
      email,
      password,
      stripe_key: token.id
    });

    //const croppedImg = await this.imageCropper.current.getCroppedImg('profile_pic');
    //formData.append('img', croppedImg);
    formData.append('paramsArray', params);
    const response = await jsonFetch('signup', { body: formData, method: 'POST' }, true);
    this.setState({inlineLoading: false});
    //console.log('signup response', response);
    if (response.success === 1) {
      localStorage.setItem('session', JSON.stringify(response.data));
      localStorage.setItem('access_token', JSON.stringify(response.token));
      toast('Signup Successful');
      setTimeout(
        function(){
          window.location.href = '/';
        }
        .bind(this),
        3000
      );
    } else {
      toast(response.message);
      alert(response.message);
    }
};

handleFields = e => this.setState({ [e.target.name]: e.target.value });

  async submit(ev) {
  let {token} = await this.props.stripe.createToken({name: "Name"});
  //console.log('token', token);
  if (!token) {
    return false
  }
  const params = JSON.stringify({ stripe_key: token.id });
  const body = JSON.stringify({ paramsArray: params });
  const response = await jsonFetch('manageStripe', { body, method: 'POST' });
  //console.log('manageStripe', response);
  if (response.success === 1) {
    localStorage.setItem('session', JSON.stringify(response.data));
    setTimeout(
      function(){
        window.location.href = '/';
      }
      .bind(this),
      3000
    );
  } else {
    alert(response.message);
  }
}

matchPassword = (value) => {
  return value && value === this.state.password;
}

onAttachmentsChange = (info) => {
  const fileArray = info.fileList;
  const fileobj = [];
  for (let i = 0; i < fileArray.length; i++) {
    fileobj.push(fileArray[i].originFileObj);
  }
  this.setState({ img: fileobj });

}

  render() {
      const {
        fname,
        lname,
        email,
        password,
        confirm_password,
        inlineLoading,
        img
      } = this.state;

      const props = {
      listType: 'picture',
    };

    return (
        <div className="signup_form">
            {inlineLoading ? <div tabIndex="0" autoFocus="true" id="inlineDim" className="inlineDim">
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </div> : ''}
          <h1>Sign Up</h1>
          <ValidationForm
              id="contactForm"
              onSubmit={(e, formData, inputs) => {
                  e.preventDefault();
                  this.handleForm(formData);
              }}
          >
              <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="fname"
                            value={fname}
                            id="fname"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your first name"
                            className="form-control input_field capital"
                            placeholder="First Name"
                            autoComplete="off"
                            autoFocus={true}
                            onChange={e => {
                    					let value = e.target.value;

                    					value = value.replace(/[^A-Za-z]/gi, "");

                    					this.setState({
                    						fname: value
                    					});
                    				}}
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="lname"
                            value={lname}
                            id="lname"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your last name"
                            className="form-control input_field capital"
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={e => {
                    					let value = e.target.value;

                    					value = value.replace(/[^A-Za-z]/gi, "");

                    					this.setState({
                    						lname: value
                    					});
                    				}}
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                        <TextInput
                            name="email"
                            value={email}
                            id="email"
                            type="email"
                            required
                            successMessage=""
                            validator={validator.isEmail}
                            errorMessage={{validator:"Please enter a valid email"}}
                            className="form-control input_field"
                            placeholder="Email"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="password"
                            value={password}
                            id="password"
                            type="password"
                            required
                            successMessage=""
                            pattern="(?=.*[A-Z]).{6,}"
                            errorMessage={{required:"Password is required", pattern: "Password should be at least 6 characters and contains at least one upper case letter"}}
                            className="form-control input_field"
                            placeholder="Password"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="confirm_password"
                            value={confirm_password}
                            id="confirm_password"
                            type="password"
                            validator={this.matchPassword}
                            required
                            successMessage=""
                            errorMessage={{required:"Confirm password is required", validator: "Password does not match"}}
                            className="form-control input_field"
                            placeholder="Confirm Password"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="example">

                        <div className="checkout">
                          <p>Payment Details</p>
                          <CardElement style={{ base: {color: '#fff'} }} />
                          {/*<button className="btn btn-secondary" onClick={this.submit}>Confirm</button>*/}
                        </div>

                    </div>
                </div>

                <div className="upload_profile_pic">
                  <h5>UPLOAD PROFILE IMAGE</h5>
                  <Upload
                      {...props}
                      onChange={this.onAttachmentsChange}
                    >
                      <Button>
                        <Icon type="upload" /> Upload
                      </Button>
                    </Upload>
                </div>

              </div>

              <div className="form-group">
                  <button className="btn btn-secondary">
                      SIGN UP
                  </button>
              </div>
              <div className="already_signup">
                Already a KEION’S KORNER Member ?  <span><a href="/signin">Sign In now</a></span>
              </div>
              <div className="clearfix" />
          </ValidationForm>
        </div>
    );
  }
}

export default injectStripe(SignupForm);
