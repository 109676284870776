import React, {Component} from 'react';
import { Button, Icon, Upload } from 'antd';
import { Dimmer, Loader } from 'semantic-ui-react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import jsonFetch from '../../common/api';
import { ValidationForm, TextInput, Radio } from "react-bootstrap4-form-validation";
import IMAGES from '../../common/images';

class CheckOutPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
        fname: '',
        lname: '',
        email: '',
        phone: '',
        address: '',
        country: '',
        city: '',
        state: '',
        zip: '',
        credit_card_id: '',
        inlineLoading: false,
        creditCards: null
    };
    this.handleFields = this.handleFields.bind(this);
    this.handleForm = this.handleForm.bind(this);
  }

  async componentDidMount() {
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    this.setState({
      currentUser: currentSession,
      fname: (currentSession && currentSession.fname != null) ? currentSession.fname : '',
      lname: (currentSession && currentSession.lname != null) ? currentSession.lname : '',
      email: (currentSession && currentSession.email != null) ? currentSession.email : '',
      phone: (currentSession && currentSession.phone != null) ? currentSession.phone : '',
      address: (currentSession && currentSession.address != null) ? currentSession.address : '',
      country: (currentSession && currentSession.country != null) ? currentSession.country : '',
      city: (currentSession && currentSession.city != null) ? currentSession.city : '',
      state: (currentSession && currentSession.state != null) ? currentSession.state : '',
      zip: (currentSession && currentSession.zip != null) ? currentSession.zip : '',
    });

    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('creditCards', { body: body, method: 'POST' });
    //console.log('creditCards response', response.data);
    this.setState({
      creditCards: response.data,
    });
  }

  async handleForm (e) {
    this.setState({inlineLoading: true});
    let {token} = await this.props.stripe.createToken({name: "Name"});
    if (!token) {
      toast('Payment Failed, Please try again');
      return false
    }

    const {
      fname,
      lname,
      email,
      phone,
      address,
      country,
      city,
      state,
      zip,
      credit_card_id
    } = this.state;

    const params = JSON.stringify({
      fname,
      lname,
      email,
      phone,
      address,
      country,
      city,
      state,
      zip,
      credit_card_id,
      stripe_key: token.id
    });

    const body = JSON.stringify({ paramsArray: params });
    // console.log(body);
    const response = await jsonFetch('', { body, method: 'POST' });
    this.setState({inlineLoading: false});
    //console.log('checkout response', response);
    if (response.success === 1) {
      toast('Order Successfully Placed');
      setTimeout(
        function(){
          window.location.href = '/products';
        }
        .bind(this),
        3000
      );
    } else {
      toast(response.message);
      alert(response.message);
    }
};

handleFields = e => this.setState({ [e.target.name]: e.target.value });

handleChange = (e, value) => {
  this.setState({
      [e.target.name]: value
  });
}

  async submit(ev) {
  let {token} = await this.props.stripe.createToken({name: "Name"});
  //console.log('token', token);
  if (!token) {
    return false
  }
  const params = JSON.stringify({ stripe_key: token.id });
  const body = JSON.stringify({ paramsArray: params });
  const response = await jsonFetch('manageStripe', { body, method: 'POST' });
  //console.log('manageStripe', response);
  if (response.success === 1) {
    localStorage.setItem('session', JSON.stringify(response.data));
    setTimeout(
      function(){
        window.location.href = '/';
      }
      .bind(this),
      3000
    );
  } else {
    alert(response.message);
  }
}

matchPassword = (value) => {
  return value && value === this.state.password;
}

  render() {
      const {
        currentUser,
        inlineLoading,
        fname,
        lname,
        email,
        phone,
        address,
        country,
        city,
        state,
        zip,
        credit_card_id,
        creditCards
      } = this.state;

      //console.log('creditCards', creditCards);

      const props = {
      listType: 'picture',
    };

    return (
        <div className="checkout_form top-padding">
            {inlineLoading ? <div tabIndex="0" autoFocus="true" id="inlineDim" className="inlineDim">
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </div> : ''}
          <ValidationForm
              id="contactForm"
              onSubmit={(e, formData, inputs) => {
                  e.preventDefault();
                  this.handleForm(formData);
              }}
          >
              <h1>Billing Details</h1>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="fname"
                            value={fname}
                            id="fname"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your first name"
                            className="form-control input_field capital"
                            placeholder="First Name"
                            autoComplete="off"
                            autoFocus="true"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="lname"
                            value={lname}
                            id="lname"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your last name"
                            className="form-control input_field capital"
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                        <TextInput
                            name="email"
                            value={email}
                            id="email"
                            type="email"
                            required
                            successMessage=""
                            errorMessage="Please enter your email address"
                            className="form-control input_field"
                            placeholder="Email"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="phone"
                            value={phone}
                            id="phone"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your phone number"
                            className="form-control input_field"
                            placeholder="Phone"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="country"
                            value={country}
                            id="country"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your country"
                            className="form-control input_field"
                            placeholder="Country"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="address"
                            value={address}
                            id="address"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your address"
                            className="form-control input_field"
                            placeholder="Address"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="city"
                            value={city}
                            id="city"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your city"
                            className="form-control input_field"
                            placeholder="City"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="state"
                            value={state}
                            id="state"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your state"
                            className="form-control input_field"
                            placeholder="State"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                        <TextInput
                            name="zip"
                            value={zip}
                            id="zip"
                            type="text"
                            required
                            successMessage=""
                            errorMessage="Please enter your zip code"
                            className="form-control input_field"
                            placeholder="Zip Code"
                            autoComplete="off"
                            onChange={
                                this.handleFields
                            }
                        />
                        <div className="help-block with-errors" />
                    </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    {creditCards && currentUser != null ? <h5>Select your Card</h5> : ''}
                    { currentUser && currentUser != null ? creditCards && creditCards.length > 0 ? creditCards.map(function(card, index){
                      //console.log('card', card);
                      return(
                        <div>
                          <Radio.RadioGroup name="credit_card_id" required errorMessage="Please select your card">
                            <Radio.RadioItem id={card.id} label={card.brand} value={card.id} />
                          </Radio.RadioGroup>
                        </div>
                      );
                    }, this) : <div className="no_data">{'No Credit Cards'}</div> : ''}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                    <div className="example">

                        <div className="checkout">
                          <h5>Payment Details</h5>
                          <CardElement style={{ base: {color: '#fff'} }} />
                        </div>

                    </div>
                </div>

              </div>

              <div className="form-group">
                  <button className="btn btn-secondary">
                      PLACE ORDER
                  </button>
              </div>
              <div className="clearfix" />
          </ValidationForm>
        </div>
    );
  }
}

export default injectStripe(CheckOutPageForm);
