import React, { Component } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import jsonFetch from '../../common/api';
import IMAGES from '../../common/images';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';

class TrialForm extends Component {
  constructor() {
      super();
      this.state = {
        inlineLoading: false,
        fname: '',
        email: '',
        heard_from: '',
        password: '',
        confirm_password: '',
        emailCheck: ''
      };
      this.handleChange = this.handleChange.bind(this);
      // this.checkEmail = this.checkEmail.bind(this);
  }

  componentDidMount(){  }

  async handleForm (e) {
    this.setState({inlineLoading: true});
    const { fname, email, heard_from, password } = this.state;
    console.log("trial form: ",fname, email, heard_from, password);
    const params = JSON.stringify({ fname, email, heard_from, password });
    const body = JSON.stringify({ paramsArray: params });
    // const response = await jsonFetch('start_trial', { body, method: 'POST' });
    const response = null;
    this.setState({inlineLoading: false});
    //console.log('login response', response);
    if (response.success === 1) {
      // console.log('response', response);
      localStorage.setItem('session', JSON.stringify(response.data));
      localStorage.setItem('access_token', JSON.stringify(response.token));
      toast('Trial Signup Successful');
      setTimeout(
        function(){
          window.location.href = '/';
        }
        .bind(this),
        3000
      );
    } else {
      toast(response.message);
      alert(response.message);
    }

  };

  handleChange = (e) =>  {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'email') {
      // this.checkEmail(e.target.value);
    }
  }

  matchPassword = (value) => {
    return value && value === this.state.password;
  }

  checkMail = (value) => {
    // console.log(value && value === 'mohiuddin@creadios.com');
    this.checkEmail(value);
    const { emailCheck } = this.state;
    console.log(emailCheck);
    setTimeout(function() {
      return emailCheck;
      console.log(emailCheck);
    }, 500);
    // console.log(check);
    // flagEmail = '';
    // return check;
  }

  async checkEmail (value) {
    const email = value;
    const params = JSON.stringify({ email });
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('check_email', { body, method: 'POST' });
    const temp = response.response;
    console.log(response);
    this.setState({ emailCheck: temp === 1 ? true : false });
  }

  ext(url) {
    return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
  }

  render() {
    const { inlineLoading, fname, email, heard_from, password, confirm_password, emailCheck } = this.state;
      return (
        <div className="trial_formwrapper">
          {inlineLoading ? <div tabIndex="0" autoFocus="true" id="inlineDim" className="inlineDim">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </div> : ''}
          <ValidationForm
              id="contactForm"
              onSubmit={(e, formData, inputs) => {
                  e.preventDefault();
                  this.handleForm(formData);
              }}
          >
                <div className="form-group">
                    <label>FIRST NAME</label>
                    <TextInput
                        name="fname"
                        value={fname}
                        id="lname"
                        type="text"
                        required
                        successMessage=""
                        errorMessage="Please enter your First name"
                        className="form-trial input_field capital"
                        placeholder="Ex. Jone Doe"
                        autoComplete="off"
                        onChange={ this.handleChange }
                    />
                    <div className="help-block with-errors" />
                </div>

                <div className="form-group">
                    <label>EMAIL ADDRESS</label>
                    <TextInput
                        name="email"
                        id="email"
                        type="text"
                        required
                        successMessage=""
                        // validator={this.checkMail}
                        // validateOn="blur"
                        errorMessage={{required: "Email Address is required", validator: "Email Address already taken, try different one"}}
                        className="form-trial input_field"
                        placeholder="Ex. jone@gmail.com"
                        autoComplete="off"
                        autoFocus={false}
                        onChange={ this.handleChange }
                    />
                    <div className="help-block with-errors" />
                </div>
                <div className="form-group">
                    <label>PASSWORD</label>
                    <TextInput
                        name="password"
                        value={password}
                        id="password"
                        type="password"
                        required
                        successMessage=""
                        pattern="(?=.*[A-Z]).{6,}"
                        errorMessage={{required:"Password is required", pattern: "Password should be at least 6 characters and contains at least one upper case letter"}}
                        className="form-trial input_field"
                        placeholder="Password"
                        autoComplete="off"
                        onChange={
                            this.handleChange
                        }
                    />
                </div>
                <div className="form-group">
                    <label>CONFIRM PASSWORD</label>
                    <TextInput
                        name="confirm_password"
                        value={confirm_password}
                        id="confirm_password"
                        type="password"
                        validator={this.matchPassword}
                        required
                        successMessage=""
                        errorMessage={{required:"Confirm password is required", validator: "Password does not match"}}
                        className="form-trial input_field"
                        placeholder="Confirm Password"
                        autoComplete="off"
                        onChange={
                            this.handleChange
                        }
                    />
                    <div className="help-block with-errors" />
                </div>
                <div className="form-group">
                    <label>HOW DID YOU HEAR ABOUT KEION’S KORNER</label>
                    <TextInput
                        name="heard_from"
                        id="heard_from"
                        multiline
                        required
                        value={this.state.heard_from}
                        onChange={this.handleChange}
                        placeholder="Write Something ..."
                        className="form-trial textcomment input_field"
                        rows="5"/>
                </div>

              <div className="form-group" style={{textAlign: 'center', letterSpacing: 5}}>
                  <button className="btn btn-secondary header_btn">
                      REQUEST TRIAL
                  </button>
              </div>

              <div className="clearfix" />
          </ValidationForm>
        </div>
      );
  }
}

export default TrialForm;
