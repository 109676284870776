import React, { Component } from "react";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import "react-modal-video/css/modal-video.min.css";
//Components List
import jsonFetch from '../common/api';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';

let currentUser;

var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

var settings1 = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

class Conferences extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        inlineLoading: false,
        Conferences: null,
        videoUrl: null,
        visible: false,
        currentUser: null,
        Categories: null
      };

      this.markFav = this.markFav.bind(this);
      this.getUniqueCategories = this.getUniqueCategories.bind(this);
      this.mustLoggedIn = this.mustLoggedIn.bind(this);
  }

  async componentDidMount() {
    document.getElementById('HEADER').scrollIntoView();
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    currentUser = currentSession;
    if (!currentUser) { window.location.href = "/signin"; }
    this.setState({
      currentUser: currentSession
    });
    this.Dataload();
  }

  Dataload = async () => {
    this.setState({inlineLoading: true});
    const params = JSON.stringify({type: 6});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('loadAssets', { body: body, method: 'POST' });
    //console.log("Conferences page", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      this.setState({
        Conferences: response.data,
      });

      let categories = [];
      response.data.map(function(data, index){
        categories.push({
          id: data.category.id,
          title: data.category.title,
        });
      });

      const categoriesData = this.getUniqueCategories(categories, 'title');

      this.setState({
        Categories: categoriesData
      });

    }
    else{
      toast(response.message);
    }
  }

  ConfDataload = async () => {
    const params = JSON.stringify({type: 6});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('loadAssets', { body: body, method: 'POST' });
    //console.log("Conferences page", response.data);
    if(response.success === 1)
    {
      this.setState({
        Conferences: response.data,
      });

      let categories = [];
      response.data.map(function(data, index){
        categories.push({
          id: data.category.id,
          title: data.category.title,
        });
      });

      const categoriesData = this.getUniqueCategories(categories, 'title');

      this.setState({
        Categories: categoriesData
      });

    }
    else{
      toast(response.message);
    }
  }

  getUniqueCategories(arr, comp) {

  const unique = arr
       .map(e => e[comp])

     // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

   return unique;
  }

  truncate(str, no_words) {
    return str.split(" ").splice(0,no_words).join(" ");
  }

  renderVideos = (catId, global) => {
    const result = this.state.Conferences.filter(items => items.type === catId);
    let count = result.length;
    return (
      <div>
        { count >= 4 ?
          <Slider {...settings}>{
            result.map(function(item, index) {
              //var videoTitle = item.title;
              //const title = videoTitle.slice(0, 20);
              const title = global.truncate(item.title, 4);
              return (
                <div key={index.toString()}>
                  <div>
                    { currentUser && currentUser != null ?
                      item.favorites && item.favorites.length ? <div className="heart_icon"> <i className="icofont-heart" /></div> : <div className="heart_icon">
                      <i className="icofont-heart fav_heart" onClick= {() => { global.markFav(item.id); }} />
                    </div> : <div className="heart_icon">
                      <i className="icofont-heart fav_heart" onClick= {() => { global.mustLoggedIn();}} />
                    </div> }
                  </div>

                  <div className="slider_item" onClick= {() => global.playVideo(item) }>
                    <img src={!item.img_url && item.img_url == null ? IMAGES.popularSlider1 :  base_url.baseImageUpload + item.img_url} alt="popular1" />

                    <div className="data_on_hover">
                      {item.category.title != null ? item.category.title : 'No Category' }
                    </div>

                    <a href="javascript:void(0)" className="btn btn-secondary popular_slider_btn">
                      <span>{title}</span><i className="icofont-ui-play" />
                    </a>
                  </div>
                </div>
              );
            })
        } </Slider> : <Slider {...settings1}>{
          result.map(function(item, index) {
            //var videoTitle = item.title;
            //const title = videoTitle.slice(0, 20);
            const title = global.truncate(item.title, 4);
            return (
              <div key={index.toString()}>
                <div>
                  { currentUser && currentUser != null ?
                    item.favorites && item.favorites.length ? <div className="heart_icon"> <i className="icofont-heart" /></div> : <div className="heart_icon">
                    <i className="icofont-heart fav_heart" onClick= {() => { global.markFav(item.id); }} />
                  </div> : <div className="heart_icon">
                    <i className="icofont-heart fav_heart" onClick= {() => { global.mustLoggedIn();}} />
                  </div> }
                </div>

                <div className="slider_item" onClick= {() => global.playVideo(item) }>
                  <img src={!item.img_url && item.img_url == null ? IMAGES.popularSlider1 :  base_url.baseImageUpload + item.img_url} alt="popular1" />

                  <div className="data_on_hover">
                    {item.category.title != null ? item.category.title : 'No Category' }
                  </div>

                  <a href="javascript:void(0)" className="btn btn-secondary popular_slider_btn">
                    <span>{title}</span><i className="icofont-ui-play" />
                  </a>
                </div>
              </div>
            );
          })
        }</Slider>
      }
      </div>
   );
 }

 async playVideo(media) {
   //console.log('media', media);
   const { currentUser } = this.state;

   if (media.is_paid === 1 && currentUser && currentUser != null) {
     this.setState({
       visible: true,
       videoUrl: media.video_url
     });

     const params = JSON.stringify({});
     const body = JSON.stringify({ paramsArray: params });
     const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });

   } else if (media.is_paid === 1 && currentUser === null) {
     confirmAlert({
       customUI: ({ onClose }) => {
         return (
           <div className="delete_popup">
             <h3>Please loggedIn to watch this video, it's Paid!</h3>
             <button type="button" onClick={onClose}>Cancel</button>
             <button
               type="button"
               onClick={() => {
                 window.location.href = '/signin';
               }}
             >
               {'Login'}
             </button>
           </div>
         );
       }
     });
   } else {
     this.setState({
       visible: true,
       videoUrl: media.video_url
     });

     const params = JSON.stringify({});
     const body = JSON.stringify({ paramsArray: params });
     const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });
     //console.log('playcount response', response);
   }

 }

  async markFav(fav_id){
    const params = JSON.stringify({});
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('fav/' + fav_id, { body, method: 'POST' });
    if (response.success === 1) {
      toast(response.message);
      this.ConfDataload();
    } else {
      toast(response.message);
    }
  }

  mustLoggedIn = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete_popup">
            <h3>Please login to mark as favorite!</h3>
            <button type="button" onClick={onClose}>Cancel</button>
            <button
              type="button"
              onClick={() => {
                window.location.href = '/signin';
              }}
            >
              {'Login'}
            </button>
          </div>
        );
      }
    });
  }

  hide() {
    this.setState({
      visible: false,
    });

    setTimeout(()=> {
      this.setState(()=> ({ videoUrl: null }))
    }, 700);
  }

  render() {
    const { Categories, currentUser, inlineLoading, Conferences, visible, videoUrl } = this.state;

      return (
        <React.Fragment>
          {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </div> : ''}
          <Helmet>
              <meta charSet="utf-8" />
              <title>Conferences | Keion Henderson</title>
          </Helmet>

          <Rodal duration={1000} closeOnEsc={true} visible={visible} onClose={this.hide.bind(this)}>
            <ReactPlayer url={videoUrl} loop={true} playing controls volume={1} width='100%' />
          </Rodal>

            {/* NavBar: src/components/common/NavBar */}
            <NavBar />
            <div className="row margin_bottom">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                <Container>
                  <div className="music_title">
                    <span>Conferences</span><img src={IMAGES.eventsIcon} alt="music" />
                  </div>
                </Container>
              </div>
            </div>
            <Container>

              { Categories && Categories != null && Categories.length > 0 ? Categories.map(function (item, index) {
                return (
                  <div key={index.toString()}>
                    <div className="section_title top-padding"><span>{item.title}</span></div>
                    <div className="popular_slick_slider">
                      {this.renderVideos(item.id, this)}
                    </div>
                  </div>
                );
              }, this) : <div className="no_data">{'No Conferences'}</div>}

            </Container>

            {/* Footer: src/components/common/Footer */}
            <Footer pageName="home" />
        </React.Fragment>
      );
  }
}

export default Conferences;
