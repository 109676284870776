import React, { Component } from "react";
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-modal-video/css/modal-video.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import jsonFetch from '../common/api';
import IMAGES from '../common/images';
import VideosSliderComp from '../components/common/VideoSliderComp';

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

var settings1 = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};


class HomeOne extends Component {
  constructor() {
      super();
      this.state = {
        currentUser: null,
        inlineLoading: false,
        Banners: null,
        Popular: null,
        Trending: null,
        RecentAdded: null,
        Conferences: null,
        Events: null,
        visible: false,
        vid_link: null,
        SliderPlaying: true,
        muted: false,
        EventsCount: 0,
      };
  }

  DataLoad = async () =>{
    this.setState({inlineLoading: true});
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('landing', { body: body, method: 'POST' });
    console.log("landing", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      var myArray = response.data.banners;
      var BannerItem = myArray[(Math.random()*myArray.length)|0];

      this.setState({
        vid_link: BannerItem.video_url
      });

      this.setState({
        Banners: BannerItem,
        Popular: response.data.popular,
        Trending: response.data.trending,
        RecentAdded: response.data.recent,
        Conferences: response.data.conferences,
        Events: response.data.events,
        EventsCount: response.data.events.length,
      });
    }
    else{
      toast(response.message);
    }
  }

  async componentDidMount(){
    document.getElementById('HEADER').scrollIntoView();
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    // if (!currentSession) { window.location.href = "/requestrial"; }
    this.setState({
      currentUser: currentSession
    });

    this.DataLoad();

  }

  DataLoadFunc = async () =>{
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('landing', { body: body, method: 'POST' });
    //console.log("landing", response.data);
    if(response.success === 1)
    {
      var myArray = response.data.banners;
      var BannerItem = myArray[(Math.random()*myArray.length)|0];

      this.setState({
        vid_link: BannerItem.video_url
      });

      this.setState({
        Banners: BannerItem,
        Popular: response.data.popular,
        Trending: response.data.trending,
        RecentAdded: response.data.recent,
        Conferences: response.data.conferences,
        Events: response.data.events,
        EventsCount: response.data.events.length,
      });
    }
    else{
      toast(response.message);
    }
  }

  getSegment = (url, index) => (
    url.replace(/^https?:\/\//, '').split('/')[index]
  )

  handleStop = () => {
    this.setState({
      vid_link: null,
      SliderPlaying: false
    });
  }

  handleStart = () => {
    const { Banners } = this.state;
    this.setState({
      vid_link: Banners.video_url,
      SliderPlaying: true
    });
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  }

  ext(url) {
    return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
  }

  render() {
    const { EventsCount, muted, vid_link, SliderPlaying, currentUser, Banners, Popular, Trending, RecentAdded, Conferences, Events, inlineLoading, visible, videoUrl } = this.state;
    var videoTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const title = videoTitle.slice(0, 10);
    const bannerTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const Btitle = bannerTitle.slice(0, 20);
    const tagLine = Banners && Banners.tag_line != null ? Banners.tag_line : 'No Tag Line';
    const Tline = tagLine.slice(0, 20);
    //const vid_link = Banners && Banners.video_url != null ? Banners.video_url : 'No Video URL';

      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Keion Henderson</title>
            </Helmet>

              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
              {/* slider */}
              <div className="why_scout_slider">
                <div className="video_upper_layer"></div>
                <ReactPlayer className="banner_slider" url={vid_link} muted={muted} loop={true} playing={SliderPlaying} controls={0} volume={1} />
                <div className="volume_icon">
                  <i onClick={this.handleToggleMuted} className={muted ? "fa fa-volume-off" : "fa fa-volume-up"} aria-hidden="true"></i>
                </div>

                <Container>
                  <div className="slider_text_btns">
                    <h3>{ Btitle }</h3>
                    <h1>{ Tline }</h1>
                  </div>
                </Container>
              </div>

              <Container>
                <div className="book_banner">
                  <a href="https://www.amazon.com/Shift-Courageously-Moving-Season/dp/1546014926/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1578589350&sr=8-1" title="The Shift - Out Now" target="_blank">
                    <img alt="The Shift" src={IMAGES.theShift} />
                  </a>
                </div>
                <div className="section_title">Popular on <span>KEION HENDERSON</span></div>

                <VideosSliderComp stopPrev={this.handleStop} startPrev={this.handleStart} vidData={Popular} DataLoadFunc={this.DataLoadFunc} />

                <div className="section_title">Trending <span>NOW</span></div>

                <VideosSliderComp stopPrev={this.handleStop} startPrev={this.handleStart} vidData={Trending} DataLoadFunc={this.DataLoadFunc} />

                <div className="ad_image">
                  <a href="http://www.bayoucitylab.com/" target="_blank">
                    <img src={IMAGES.BCLBanner} alt="BCLBanner" />
                  </a>
                </div>

                <div className="section_title">Recently <span>ADDED</span></div>

                <VideosSliderComp stopPrev={this.handleStop} startPrev={this.handleStart} vidData={RecentAdded} DataLoadFunc={this.DataLoadFunc} />

                <div className="section_title">Latest <span>CONFERENCES</span></div>

                <VideosSliderComp stopPrev={this.handleStop} startPrev={this.handleStart} vidData={Conferences} DataLoadFunc={this.DataLoadFunc} />

                <div className="section_title">Latest <span>EVENTS</span></div>

                {/*<div><img src={IMAGES.EventsBanner} alt="Events" /></div>*/}

                <div>
                  {EventsCount >= 4 ?
                  <Slider {...settings}>  {
                    !Events ? null : Events.map(function(item, index) {
                      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                      ];
                      const Estartdate = moment(item.event_date).format('MM/DD/YYYY');
                      const Eenddate = moment(item.event_end_date).format('MM/DD/YYYY');
                      const date = new Date(item.event_date);
                      const event_month = monthNames[date.getMonth()];
                      var event_day = date.getUTCDate();
                      var eventDetails = item.details;
                      const details = eventDetails.slice(0, 150);
                      return (
                        <div key={index.toString()}>
                          <a href={'/event-details/' + item.id}>
                            <div className="latest_events">
                              <div className="event_header">
                                <div className="event_red_side">{event_month}</div>
                                <div className="event_black_side">{event_day}</div>
                              </div>
                              <div className="event_content">
                                <h3>{item.title}</h3>
                                <p>{details}</p>
                                <div className="start_end_date">
                                  <span>Start Date : {Estartdate}</span>
                                  <span>End Date : {Eenddate}</span>
                                </div>
                                <div className="arrow_view_more">
                                  <img src={IMAGES.arrow} alt="arrow" />
                                  <span>
                                    VIEW MORE
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                    );
                  })
                  }
                </Slider> :

                <Slider {...settings1}>  {
                  !Events ? null : Events.map(function(item, index) {
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                    ];
                    const Estartdate = moment(item.event_date).format('MM/DD/YYYY');
                    const Eenddate = moment(item.event_end_date).format('MM/DD/YYYY');
                    const date = new Date(item.event_date);
                    const event_month = monthNames[date.getMonth()];
                    var event_day = date.getUTCDate();
                    var eventDetails = item.details;
                    const details = eventDetails.slice(0, 150);
                    return (
                      <div key={index.toString()}>
                        <a href={'/event-details/' + item.id}>
                          <div className="latest_events">
                            <div className="event_header">
                              <div className="event_red_side">{event_month}</div>
                              <div className="event_black_side">{event_day}</div>
                            </div>
                            <div className="event_content">
                              <h3>{item.title}</h3>
                              <p>{details}</p>
                              <div className="start_end_date">
                                <span>Start Date : {Estartdate}</span>
                                <span>End Date : {Eenddate}</span>
                              </div>
                              <div className="arrow_view_more">
                                <img src={IMAGES.arrow} alt="arrow" />
                                <span>
                                  VIEW MORE
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                  );
                })
                }
               </Slider>
                }
              );
                </div>

              </Container>
              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default HomeOne;
