import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import jsonFetch from '../common/api';
import IMAGES from '../common/images';
import PreloginVideoSlider from '../components/common/PreloginVideoSlider';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import TrialForm from "../components/common/TrialForm";
//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import { addToCart } from '../components/actions/cartActions'

class TheShift extends Component {
  constructor() {
      super();
      this.state = {
        currentUser: null,
        inlineLoading: false,
        Banners: null,
        Popular: null,
        visible: false,
        vid_link: null,
        SliderPlaying: true,
        muted: false,
      };
  }

  DataLoad = async () =>{
    this.setState({inlineLoading: true});
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('landing', { body: body, method: 'POST' });
    console.log("landing", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      var myArray = response.data.banners;
      var BannerItem = myArray[(Math.random()*myArray.length)|0];

      this.setState({
        vid_link: BannerItem.video_url
      });

      this.setState({
        Banners: BannerItem,
        Popular: response.data.popular,
      });
    }
    else{
      toast(response.message);
    }
  }

  async componentDidMount(){
    document.getElementById('HEADER').scrollIntoView();
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    // if (!currentSession) { window.location.href = "/signin"; }
    this.setState({
      currentUser: currentSession
    });
    // this.DataLoad();
  }

  DataLoadFunc = async () =>{
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('landing', { body: body, method: 'POST' });
    //console.log("landing", response.data);
    if(response.success === 1)
    {
      var myArray = response.data.banners;
      var BannerItem = myArray[(Math.random()*myArray.length)|0];

      this.setState({
        vid_link: BannerItem.video_url
      });

      this.setState({
        Banners: BannerItem,
        Popular: response.data.popular
      });
    }
    else{
      toast(response.message);
    }
  }

  getSegment = (url, index) => (
    url.replace(/^https?:\/\//, '').split('/')[index]
  )

  handleStop = () => {
    this.setState({
      vid_link: null,
      SliderPlaying: false
    });
  }

  handleStart = () => {
    const { Banners } = this.state;
    this.setState({
      vid_link: Banners.video_url,
      SliderPlaying: true
    });
  }


  handleClick = (id)=> {
    // this.setState({inlineLoading: true});
    if (window.confirm('I understand that this purchase is for local pick up only at The Lighthouse Church, located at 6650 rankin road Humble Texas 77396.')) {
      this.props.addToCart(id);
      toast('Item added to Cart');
      setTimeout(
        function () {
          window.location.href = "/cart";
        },
        3000
      );

    }
    return false;

   }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  }


  ext(url) {
    return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
  }

  render() {
    const { muted, vid_link, SliderPlaying, currentUser, Banners, Popular, inlineLoading, visible, videoUrl } = this.state;
    var videoTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const title = videoTitle.slice(0, 10);
    const bannerTitle = Banners && Banners.title != null ? Banners.title : 'No Title';
    const Btitle = bannerTitle.slice(0, 20);
    const tagLine = Banners && Banners.tag_line != null ? Banners.tag_line : 'No Tag Line';
    const Tline = tagLine.slice(0, 20);
    //const vid_link = Banners && Banners.video_url != null ? Banners.video_url : 'No Video URL';

      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>The Shift | Keion's Korner</title>
            </Helmet>

              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
              {/* slider */}

              <div className="theshift_header">
                <Container>
                  <div className="theshift_subtitle">BY KEION <span>HENDERSON</span></div>
                  <h1 className="theshift_title">The Shift</h1>
                </Container>
              </div>

              <Container>
                <div className="row theshift_section">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="theshift_about">
                      <div className="theshift_subtitle">ABOUT <span>THE BOOK</span></div>
                      <h3 className="about_redtitle">Courageously Moving<br />From Season To Season</h3>
                      <p>Discover a renewed sense of your purpose and the courage to move from what it is to what can be.</p>
                      <div className="row">
                        <div className="col-md-6 btn_section">
                          <a onClick={()=>{this.handleClick(1)}} className="btn-secondary order_btn">ORDER NOW</a>
                          <div className="btn_note"><i>I WANT TO PICKUP LOCALY</i></div>
                        </div>
                        <div className="col-md-6 btn_section">
                          <a href="https://www.hachettebookgroup.com/titles/keion-henderson/the-shift/9781546014928/?preview=true&lens=worthy-books" target="_blank" className="purchase_btn">PURCHASE THE BOOK</a>
                          <div className="btn_note"><i>I WANT MY BOOK SHIPPED</i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="theshift_cover"><img src={IMAGES.theShiftCover} /></div>
                  </div>
                </div>
              </Container>

              <div className="theshift_section bg_gray">
                <Container>
                  <div className="theshift_subtitle">ABOUT <span>THE SHIFT</span></div>
                  <div className="videoFrame">
                    <iframe src="https://www.youtube.com/embed/Molf2Vugvpc" width="100%" height="520" allowfullscreen="allowfullscreen">
                    </iframe>
                  </div>
                </Container>
              </div>

              <Container>
                <div className="row theshift_section">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="theshift_about">
                      <div className="theshift_subtitle">MEET <span>THE AUTHOR</span></div>
                      <h3 className="about_redtitle">Keion Henderson</h3>
                      <p>Keion Henderson’s life-changing words have inspired audiences around
                        the world to take action. As a global entrepreneur, he is passionate
                        about developing leaders, organizations, and teams for optimal success.
                        Keion is founder of the annual L3: Lift, Lead and Learn Conference
                        and Business Lab, a global coaching and accelerator program for
                        entrepreneurs and corporate and ministry leaders. As an international
                        speaker, Keion has traveled the globe to share proven strategies for
                        business and leadership development.</p>
                        <p>Keion has been a CNN Heroes Award nominee and recognized as one
                        of John Maxwell’s Top 250 Leaders. He is the senior pastor of The
                        Lighthouse Church where he leads to more than 10,000 members. His
                        greatest joy is being a father to his daughter, Katelyn Henderson.
                        They live in Houston, Texas.  </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="theshift_cover"><img src={IMAGES.theAuthor} /></div>
                  </div>
                </div>
              </Container>

              <div className="theshift_section bg_red">
                <Container>
                  <div className="theshift_subtitle">FROM <span>KEION HENDERSON</span></div>
                  <h1 className="theshift_title marginBottom0">Shifting To Survive</h1>
                </Container>
              </div>

              <Container>
                <div className="theshift_section">
                  <div className="theshift_subtitle txt_red">Chapter 1</div>
                  <h1 className="theshift_title marginBottom0">Death, Divorce, And Daddy Issues</h1>
                  <div className="chapter_section">
                    <p>Shifts occur all around you every day.</p>

                    <p>Like seismic tremors rocking the ground beneath your feet, they may not be noticeable at first. You live your life, go to
                    work, come home, spend what time you can with your family, take care of chores and errands, relax a little if possible, and start
                     all over again. Throw in neighbors and friends, community service, church involvement, and extracurriculars, and there’s no
                    opportunity to stop and feel the ground shake beneath you.</p>

                    <p>Then suddenly it becomes the only thing you can feel.</p>

                    <a href="#" className="btn-secondary order_btn">READ MORE</a>
                  </div>
                </div>
              </Container>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

const mapStateToProps = (state)=>{
    return {
      items: state.items
    }
  }
const mapDispatchToProps= (dispatch)=>{

    return {
        addToCart: (id)=>{dispatch(addToCart(id))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TheShift);