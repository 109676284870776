import Item1 from '../../assets/images/the-shift-cover.jpg'
import Item2 from '../../assets/images/the-shift-cover.jpg'
import Item3 from '../../assets/images/the-shift-cover.jpg'
import Item4 from '../../assets/images/item4.jpg'
import Item5 from '../../assets/images/item5.jpg'
import Item6 from '../../assets/images/item6.jpg'
import { ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,ADD_SHIPPING } from '../actions/action-types/cart-actions'


const initState = {
    items: [
        {id:1,title:'The Shift', desc: "Discover a renwed sense of your purpose and courage to move from what it is to what can be.", price:20,img:Item1},
        {id:2,title:'The Shift 2', desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, ex.", price:40,img: Item2},
        {id:3,title:'The Shift 3', desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, ex.",price:60,img: Item3},
    ],
    addedItems:[],
    total: 0

}
const cartReducer= (state = initState,action)=>{



    //INSIDE HOME COMPONENT
    if(action.type === ADD_TO_CART){
          // console.log(action.type, state.addedItems);
          let addedItem = state.items.find(item=> item.id === action.id)
          //check if the action id exists in the addedItems
         let existed_item = state.addedItems.find(item=> action.id === item.id);

         let existingEntries = JSON.parse(localStorage.getItem("allEntries"));

         if(existingEntries == null) existingEntries = [];

         let existed_product = existingEntries.find(item=> action.id === item.id);
         // console.log(existed_product);

         if(existed_product) {
              existed_product.quantity += 1;
              let newTotal = JSON.parse(localStorage.getItem("total")) + addedItem.price;
              localStorage.setItem("total", JSON.stringify(newTotal));
              localStorage.setItem("allEntries", JSON.stringify(existingEntries));
              console.log(existingEntries, existed_product);

         } else {

           addedItem.quantity = 1;
           //calculating the total
           let newTotal = JSON.parse(localStorage.getItem("total")) + addedItem.price;

           let existingEntries = JSON.parse(localStorage.getItem("allEntries"));
           // console.log(existingEntries);
           if(existingEntries == null) existingEntries = [];
           // localStorage.setItem("entry", JSON.stringify(addedItem));
           // Save allEntries back to local storage
           existingEntries.push(addedItem);
           localStorage.setItem("allEntries", JSON.stringify(existingEntries));
           localStorage.setItem("total", JSON.stringify(newTotal));
           console.log("added items", addedItem, newTotal);
         }

         if(existed_item)
         {
           console.log(addedItem);
            addedItem.quantity += 1
             return{
                ...state,
                 total: state.total + addedItem.price
                  }
        }
         else {
           // localStorage.clear();
           // console.log("addedItem",addedItem);
            addedItem.quantity = 1;
            //calculating the total
            let newTotal = state.total + addedItem.price;
            return {
                ...state,
                addedItems: [...state.addedItems, addedItem],
                total : newTotal
            }
        }
    }
    if(action.type === REMOVE_ITEM){
        // console.log(action.id);
        let existingEntries = JSON.parse(localStorage.getItem("allEntries"));
        let itemToRemove= existingEntries.find(item=> action.id === item.id)
        let new_items = existingEntries.filter(item=> action.id !== item.id)

        let newTotal = JSON.parse(localStorage.getItem("total")) - (itemToRemove.price * itemToRemove.quantity );

        localStorage.setItem("total", JSON.stringify(newTotal));
        localStorage.setItem("allEntries", JSON.stringify(new_items));
        //calculating the total
        // let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity )
        // console.log(itemToRemove);
    }
    //INSIDE CART COMPONENT
    if(action.type=== ADD_QUANTITY){
        // console.log(action.id);
        let existingEntries = JSON.parse(localStorage.getItem("allEntries"));
        let addedItem = existingEntries.find(item=> item.id === action.id)
          addedItem.quantity += 1
          let newTotal = JSON.parse(localStorage.getItem("total")) + addedItem.price;
          localStorage.setItem("total", JSON.stringify(newTotal));
          localStorage.setItem("allEntries", JSON.stringify(existingEntries));
    }
    if(action.type=== SUB_QUANTITY){
        console.log(action.id);
        let existingEntries = JSON.parse(localStorage.getItem("allEntries"));
        let addedItem = existingEntries.find(item=> item.id === action.id)
        //if the qt == 0 then it should be removed
        if(addedItem.quantity === 1){
            let new_items = existingEntries.filter(item=>item.id !== action.id)
            let newTotal = JSON.parse(localStorage.getItem("total")) - addedItem.price
            localStorage.setItem("total", JSON.stringify(newTotal));
            localStorage.setItem("allEntries", JSON.stringify(new_items));
        }
        else {
            addedItem.quantity -= 1
            let newTotal = JSON.parse(localStorage.getItem("total")) - addedItem.price
            localStorage.setItem("total", JSON.stringify(newTotal));
            localStorage.setItem("allEntries", JSON.stringify(existingEntries));
        }
    }

    if(action.type=== ADD_SHIPPING){
          return{
              ...state,
              total: state.total + 6
          }
    }

    if(action.type=== 'SUB_SHIPPING'){
        return{
            ...state,
            total: state.total - 6
        }
  }

  else{
    return state
    }

}

export default cartReducer
