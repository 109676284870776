import React, { Component } from "react";
import { Cart } from 'react-cart-components';
import { NavLink, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, NavDropdown, Nav, Dropdown } from "react-bootstrap";
import base_url from '../../common/baseUrl';
import IMAGES from '../../common/images';

class NavBar extends Component {
  constructor() {
      super();
      this.state = {
        currentUser: null,
        cartItems: ''
      };
      this.handleCheckout = this.handleCheckout.bind(this);
      this.productToRemove = this.productToRemove.bind(this);
  }
    async componentDidMount() {
        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("is-sticky");
            } else {
                elem.classList.remove("is-sticky");
            }
        });
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        this.setState({ scrollWithOffset });

        const currentSession = await JSON.parse(localStorage.getItem("session"));
        const temp = JSON.parse(localStorage.getItem("allEntries"));
        // console.log(temp);
        const cartItems = temp ? JSON.parse(localStorage.getItem("allEntries")).length : '0';
        //console.log('currentSession', currentSession);
        this.setState({
          currentUser: currentSession,
          cartItems: cartItems
        });
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    onLogout = () => {
      localStorage.removeItem('session');
      localStorage.removeItem('access_token');
      localStorage.clear();
      window.location.href = '/';

    }

    handleCheckout = (data) => {
      //console.log('product_items',JSON.stringify(data));
      localStorage.setItem('product_items', JSON.stringify(data));
      window.location.href = '/checkout';
    }

    productToRemove = () => {
      alert('remove product');
    }

    render() {
      const { currentUser, cartItems } = this.state;
        return (
            <React.Fragment>
            {/*<Cart currency="USD" handleCheckout={this.handleCheckout} productToRemove={this.productToRemove} />*/}
                <Navbar
                    // sticky="top"
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-lg navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                      <div className="row header_row" id="HEADER">
                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                          {this.props.pageName === "home" ? (
                              <React.Fragment>
                                  <Link
                                      activeClass="active"
                                      to="home"
                                      spy={true}
                                      smooth={true}
                                      offset={-200}
                                      duration={800}
                                      className="navbar-brand"
                                  >
                                      <img src={IMAGES.logo} alt="logo" />
                                  </Link>
                              </React.Fragment>
                          ) : (
                              <LinkContainer exact to="/">
                                  <Navbar.Brand className="navbar-brand logo">
                                      <img src={IMAGES.logo} alt="logo" />
                                  </Navbar.Brand>
                              </LinkContainer>
                          )}

                        </div>

                        <div className="col-lg-7 col-md-7 col-sm-6 col-xs-6">
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn"
                        />
                          <Navbar.Collapse id="basic-navbar-nav">
                              <Nav>
                                <React.Fragment>

                                {this.props.pageName === "home" ? (
                                      <Link
                                          activeClass="active"
                                          to="home"
                                          spy={true}
                                          smooth={true}
                                          offset={-200}
                                          duration={800}
                                          className="nav-link homenav"
                                      >
                                          HOME
                                      </Link>
                                  ) : (
                                      <LinkContainer exact to="/">
                                          <Nav.Link>HOME</Nav.Link>
                                      </LinkContainer>
                                  )}

                                  <LinkContainer to="/sermons">
                                    <Nav.Link>Sermons</Nav.Link>
                                  </LinkContainer>

                                  <LinkContainer to="/music-playlist">
                                    <Nav.Link>MUSIC</Nav.Link>
                                  </LinkContainer>

                                  <LinkContainer to="/conferences">
                                    <Nav.Link>CONFERENCES</Nav.Link>
                                  </LinkContainer>

                                  <LinkContainer to="/events">
                                    <Nav.Link>EVENTS</Nav.Link>
                                  </LinkContainer>

                                  <LinkContainer to="/theshift">
                                    <Nav.Link>THE SHIFT</Nav.Link>
                                  </LinkContainer>

                                  <LinkContainer to="/webinar">
                                    <Nav.Link>WEBINAR</Nav.Link>
                                  </LinkContainer>

                                  <LinkContainer to="/podcasts">
                                    <Nav.Link>PODCASTS</Nav.Link>
                                  </LinkContainer>

                                  {/*<LinkContainer to="/products">
                                    <Nav.Link>PRODUCTS</Nav.Link>
                                  </LinkContainer>*/}

                                </React.Fragment>
                              </Nav>
                          </Navbar.Collapse>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 no-padding">
                          <div className="header_right_side">
                            <div className="heaader_cart">
                              <span className="cart_count">{cartItems}</span>
                              <a href="/cart"><i className="icofont-cart icon cross" /></a>
                            </div>
                            {currentUser && currentUser !== null ?
                              <Link to="/edit-user/"><span className="user_name">{currentUser.fname && currentUser.fname !== null  ? currentUser.fname : ''} {currentUser.lname && currentUser.lname !== null  ? currentUser.lname : ''}</span></Link>
                            : null
                            }
                            {/*<span className="search_icon"><img src={IMAGES.searchIcon} alt="search" /></span>*/}
                            {!currentUser || currentUser === null ? <span><Link to="/signup">Sign up</Link> <span style={{ color: '#ffffff' }}>/</span> <Link to="/signin">Sign in</Link></span> :
                              <span>
                                <span className="profile_pic">
                                  <img className="user_profile_img" src={(currentUser && currentUser.profile_pic != null) ? base_url.baseImageUpload + currentUser.profile_pic :  IMAGES.profilePic } alt="profile pic" />
                                </span>
                                <div className="dropdown">
                                  <button className="dropbtn"><i className="icofont-caret-down"></i></button>
                                  <div className="dropdown-content">
                                    <Link to="/favorites">Favorites</Link>
                                    {currentUser || currentUser != null ? '' : <a href="/signup">Sign up</a> }
                                    {!currentUser || currentUser === null ? <a href="/signin">Sign in</a> : <button onClick={this.onLogout} className="btn btn-block btn-secondary">Logout</button>}
                                  </div>
                                </div>
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default NavBar;
