import React, { Component } from "react";
import { Container } from "react-bootstrap";
import ReactMediaVisualizer from 'react-media-visualizer'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
//Components List
import jsonFetch from '../common/api';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import Main from "../components/common/Main";
import IMAGES from '../common/images';

let currentUser;

class MusicPlaylist extends Component {
  constructor(props) {
      super(props);
      this.state = {
        coverPic: null,
        albumTitle: '',
        songsCount: '',
        inlineLoading: false,
        MusicPlayList: '',
        playlist: [],
        selectedAlbum: null,
        playlistIsPlaying: false,
        currentSongIndex: 0,
        theme: 'soundcloud'
      };
      this.receiveStateUpdates = this.receiveStateUpdates.bind(this);
      this.updatePlaylist = this.updatePlaylist.bind(this);
  }

  async componentDidMount(){
    document.getElementById('HEADER').scrollIntoView();
    currentUser = localStorage.getItem("session");
    if (!currentUser) { window.location.href = "/signin"; }
    if(currentUser){
      currentUser = JSON.parse(currentUser);
    }
    this.setState({inlineLoading: true});
    const params = JSON.stringify({});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('getAlbums', { body: body, method: 'POST' });
    //console.log("getAlbums", response);
    this.setState({inlineLoading: false});
    if(response.success === 1)
    {
      this.setState({
        playlist: response.data[0].files,
        coverPic: response.data[0].cover,
        albumTitle: response.data[0].name,
        songsCount: response.data[0].files.length,
        selectedAlbum: response.data[0]
      });
      //console.log("selectedAlbum:", this.state.selectedAlbum);
    }
    else{
      toast(response.message);
    }
  }

  receiveStateUpdates(payload) {
    if (payload.theme) {
      //console.log('payload', payload);
    }
    this.setState(payload)
  }

  updatePlaylist(playlist, currentSongIndex) {
    //console.log('update song', playlist);
    // New playlist received, get array containing song sources
    if(!currentUser || currentUser === null){
      window.location.href = '/signin';
    } else if (currentUser && currentUser != null && currentUser.is_paid_user === 0) {
      window.location.href = '/subscribe';
    } else {
      playlist = playlist.map(song => song.src)
      if (Number.isInteger(currentSongIndex)) {
        if (JSON.stringify(playlist) !== JSON.stringify(this.state.playlist))
          this.setState({ playlist, playlistIsPlaying: true, currentSongIndex: currentSongIndex })
        // Not new playlist, just toggling play or pause
        else this.setState({ playlistIsPlaying: !this.state.playlistIsPlaying })
        // New Song index
        if (currentSongIndex !== this.state.currentSongIndex) this.setState({ currentSongIndex })
      } else {
        if (JSON.stringify(playlist) !== JSON.stringify(this.state.playlist))
          this.setState({ playlist, playlistIsPlaying: true, currentSongIndex: 0 })
        else this.setState({ playlistIsPlaying: !this.state.playlistIsPlaying })
      }
    }
  }

  render() {
    const { inlineLoading, currentSongIndex, coverPic, albumTitle, songsCount, playlist } = this.state;
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Music | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                  <Container>
                    <div className="music_title">
                      <span>Music</span><img src={IMAGES.musicIcon} alt="music" />
                    </div>
                    {/*<button className="btn btn-secondary ">Back <i className="icofont-undo" /></button>*/}
                  </Container>
                </div>
              </div>
              <Container>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <div className="album_image">
                        <img src={ !coverPic || coverPic == null ? IMAGES.albumPic : coverPic } alt="album" />
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                      <div className="album_details">
                        <h6>ALBUM</h6>
                        <h1>{ !albumTitle ? '' : albumTitle }</h1>
                        <h5>ARTIST : <span>KEION HENDERSON</span></h5>
                        <h5>TRACKS : <span>{ !songsCount ? 0 : songsCount }</span></h5>
                        {/*<button className="btn btn-secondary ">
                            <i className="icofont-ui-play" />
                            <span>PLAY</span>
                        </button>*/}
                      </div>
                    </div>
                </div>

                <div className="row">
                  <React.Fragment>
                    <div className="react-media-vis-body" style={{width: '100%'}}>
                      <Main
                        updatePlaylist={this.updatePlaylist}
                        selectedAlbum={this.state.selectedAlbum}
                        currentSongIndex={this.state.currentSongIndex}
                        playlist={this.state.playlist}
                        theme={this.state.theme}
                        playlistIsPlaying={this.state.playlistIsPlaying} />
                    </div>
                    {currentUser && currentUser != null && currentUser.is_paid_user === 1 ? <ReactMediaVisualizer
                      playlist={this.state.playlist}
                      receiveStateUpdates={this.receiveStateUpdates}
                      playlistIsPlaying={this.state.playlistIsPlaying}
                      theme={this.state.theme}
                      currentSongIndex={this.state.currentSongIndex} /> : ''}
                  </React.Fragment>
                </div>

              </Container>
              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default MusicPlaylist;
