import React, { Component } from "react";
import { Button, Icon, Upload } from 'antd';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import jsonFetch from '../common/api';
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

let currentUser;

class EditUser extends Component {
  constructor(props) {
      super(props);
      this.state = {
        fname: '',
        lname: '',
        email: '',
        currentUser: null,
        img: null
    };
    this.handleFields = this.handleFields.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.onAttachmentsChange = this.onAttachmentsChange.bind(this);
  }

  async componentDidMount() {
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    this.setState({
      currentUser: currentSession,
      fname: currentSession.fname || '',
      lname: currentSession.lname || '',
      email: currentSession.email || '',
    });
  }

    async handleForm (e) {
      const { fname, lname, email, img } = this.state;
      const formData = new FormData();
      if (this.state.img != null) {
        for (let i = 0; i < this.state.img.length; i++) {
          formData.append('img', this.state.img[i]);
        }
      }

      const params = JSON.stringify({ fname, lname, email });
      formData.append('paramsArray', params);
      const response = await jsonFetch('editUser', { body: formData, method: 'POST' }, true);
      if (response.success === 1) {
        localStorage.setItem('session', JSON.stringify(response.data));
        toast(response.message);
        setTimeout(
          function () {
            window.location.reload();
          },
          2000
        );
      } else {
        toast(response.message);
      }
    }

  handleFields = e => this.setState({ [e.target.name]: e.target.value });

  onAttachmentsChange = (info) => {
    const fileArray = info.fileList;
    const fileobj = [];
    for (let i = 0; i < fileArray.length; i++) {
      fileobj.push(fileArray[i].originFileObj);
    }
    this.setState({ img: fileobj });

  }

  render() {
    const { currentUser, fname, lname, email, img } = this.state;
    const props = {
    listType: 'picture',
  };

      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit User | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />

              <div className="page_bg">
                <div className="signup_form">
                  <h1>Edit User</h1>
                  <ValidationForm
                      id="contactForm"
                      onSubmit={(e, formData, inputs) => {
                          e.preventDefault();
                          this.handleForm(formData);
                      }}
                  >
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <TextInput
                                    name="fname"
                                    value={fname}
                                    id="fname"
                                    type="text"
                                    required
                                    successMessage=""
                                    errorMessage="Please enter your first name"
                                    className="form-control input_field capital"
                                    placeholder="Enter First Name"
                                    autoComplete="off"
                                    onChange={
                                        this.handleFields
                                    }
                                />
                                <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <TextInput
                                    name="lname"
                                    value={lname}
                                    id="lname"
                                    type="text"
                                    required
                                    successMessage=""
                                    errorMessage="Please enter your last name"
                                    className="form-control input_field capital"
                                    placeholder="Enter Last Name"
                                    autoComplete="off"
                                    onChange={
                                        this.handleFields
                                    }
                                />
                                <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <TextInput
                                    name="email"
                                    value={email}
                                    id="email"
                                    type="email"
                                    required
                                    successMessage=""
                                    errorMessage="Please enter your email address"
                                    className="form-control input_field"
                                    placeholder="Email or phone number"
                                    autoComplete="off"
                                    readOnly
                                    disabled
                                    onChange={
                                        this.handleFields
                                    }
                                />
                                <div className="help-block with-errors" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <img className="user_img" src={(currentUser && currentUser.profile_pic != null) ? base_url.baseImageUpload + currentUser.profile_pic :  IMAGES.profilePic } alt="profile pic" />
                        </div>


                        <div className="upload_profile_pic">
                          <h5>UPLOAD PROFILE IMAGE</h5>
                          <Upload
                              {...props}
                              onChange={this.onAttachmentsChange}
                            >
                              <Button>
                                <Icon type="upload" /> Upload
                              </Button>
                            </Upload>
                        </div>

                      </div>

                      <div className="form-group">
                          <button className="btn btn-secondary">
                              UPDATE
                          </button>
                      </div>
                      <div className="clearfix" />
                  </ValidationForm>
                </div>
              </div>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default EditUser;
