import React, { Component } from "react";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import {Helmet} from "react-helmet";
import jsonFetch from '../common/api';

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class SignIn extends Component {
  constructor(props) {
      super(props);
      this.state = {
        inlineLoading: false,
        email: "",
        password: ""
      };
      this.handleFields = this.handleFields.bind(this);
      this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  async handleForm (e) {
    this.setState({inlineLoading: true});
    const { email, password } = this.state;
    const params = JSON.stringify({ email, password });
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('login', { body, method: 'POST' });
    this.setState({inlineLoading: false});
    //console.log('login response', response);
    if (response.success === 1) {
      localStorage.setItem('session', JSON.stringify(response.data));
      localStorage.setItem('access_token', JSON.stringify(response.token));
      toast("Login Successful");
      setTimeout(
        function(){
          window.location.href = '/';
        }
        .bind(this),
        500
      );
    } else {
      toast(response.message);
    }
  };

  handleFields = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { inlineLoading } = this.state;
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SignIn | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" autoFocus="true" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
              <div className="page_bg">
                <div className="signup_form">
                  <h1>Sign In</h1>
                  <ValidationForm
                      id="contactForm"
                      onSubmit={(e, formData, inputs) => {
                          e.preventDefault();
                          this.handleForm(formData);
                      }}
                  >
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <TextInput
                                    name="email"
                                    id="email"
                                    type="text"
                                    required
                                    successMessage=""
                                    errorMessage="Please enter your email address"
                                    className="form-control input_field"
                                    placeholder="Email"
                                    autoComplete="off"
                                    autoFocus={true}
                                    onChange={
                                        this.handleFields
                                    }
                                />
                                <div className="help-block with-errors" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <TextInput
                                    name="password"
                                    id="password"
                                    type="password"
                                    //required
                                    successMessage=""
                                    errorMessage="Please enter password"
                                    className="form-control input_field"
                                    placeholder="Password"
                                    autoComplete="off"
                                    onChange={
                                        this.handleFields
                                    }
                                />
                            </div>
                        </div>

                      </div>

                      <div className="form-group">
                          <button className="btn btn-secondary">
                              SIGN IN
                          </button>
                      </div>
                      <div className="already_signup">
                        New to KEION’S KORNER ? <span><a href="/signup">Sign up now</a></span>
                      </div>
                      <div className="clearfix" />
                  </ValidationForm>
                </div>
              </div>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default SignIn;
