import React, { Component } from 'react'
import PodCastShowPlaylists from './MusicSelect/PodCastShowPlaylists'


export default class PodCastMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      media: []
    }
    this.selectAlbum = this.selectAlbum.bind(this)
    this.clearSelectedAlbum = this.clearSelectedAlbum.bind(this)
  }

  render() {
    return (
      <main>
        <div className="albums">
        {this.props.selectedAlbum
          ? <PodCastShowPlaylists
              clearSelectedAlbum={this.clearSelectedAlbum}
              selectedAlbum={this.props.selectedAlbum}
              playlist={this.props.playlist}
              updatePlaylist={this.props.updatePlaylist}
              playlistIsPlaying={this.props.playlistIsPlaying}
              currentSongIndex={this.props.currentSongIndex} />
              : <div>Media Loading</div>}

        </div>
      </main>
    )
  }

  selectAlbum(selectedAlbum) {
    this.setState({ selectedAlbum })
  }

  clearSelectedAlbum() {
    this.setState({ selectedAlbum: null })
  }
}
