let baseURL;
if(window.location.hostname === 'localhost')
  baseURL = 'https://khprime.iambrandsdev.com/api/';
else
  baseURL = '/api/';
const jsonFetch = async (functionname, options = {}, file = null) => {
  try {
        const url = baseURL + functionname;
        options['headers'] = headers(file)
        const response = await fetch(url, options);
        const jsonResponse = await response.json();
        if(jsonResponse.response == -2){
          localStorage.removeItem('session');
          localStorage.removeItem('access_token');
          window.location.reload();
        }
        return jsonResponse;
  } catch (error) {
    return buildError(error);
  }
};

const buildError = (error = '') => ({ success: false, error });

const headers = (file) => {
  const returnValue = {
    Accept: 'application/json',
  };
  if(file == null)
    returnValue['Content-Type'] = 'application/json';
  if (localStorage.getItem('access_token') != null) { returnValue['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('access_token')) }
  return returnValue;
};

export default jsonFetch;
