import React, { Component } from "react";
import moment from 'moment';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import "react-modal-video/css/modal-video.min.css";
//Components List
import jsonFetch from '../common/api';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';
import EventPageComp from '../components/common/EventPageComp';
import GroupByarray from 'group-array';
import dateFormat from 'dateformat';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

var settings1 = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};


class Events extends Component {
  constructor(props) {
      super(props);
      this.state = {
        inlineLoading: false,
        EventsGroupBy: null,
        Events: null,
        EventsCount: 0
      };
  }

  async componentDidMount() {
    document.getElementById('HEADER').scrollIntoView();
    const currentUser = await JSON.parse(localStorage.getItem("session"));
    if (!currentUser) { window.location.href = "/signin"; }
    // this.LandingEvents();
    this.Dataload();
  }

  async Dataload () {
    this.setState({ inlineLoading: true });
    const paramsArray = {};
    const response = await jsonFetch('getEvents', { body: paramsArray, method: 'POST' });
    console.log('events page response', response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1)
      {
          const arr = response.data;

          const CurrentYearMonth = moment().format('YYYY-MM');
          const FilteredEvent = arr.filter(function(item) {
            return moment(item.event_date).format('YYYY-MM') === CurrentYearMonth.toString();
          });

          console.log('FilteredEvent', FilteredEvent);

          this.setState({
            Events: FilteredEvent
          });
      }
      else{
        toast(response.message);
      }
  }

  truncate(str, no_words) {
    return str.split(" ").splice(0,no_words).join(" ");
  }

  render() {
    const { inlineLoading, Events } = this.state;
      return (
        <React.Fragment>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Events | Keion Henderson</title>
          </Helmet>

            {/* NavBar: src/components/common/NavBar */}
            <NavBar />
            <div className="row">
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                  <Container>
                    <div className="music_title">
                      <span>Events</span><img src={IMAGES.eventsIcon} alt="music" />
                    </div>
                  </Container>
                </div>
             </div>

             <Container>
               <div className="row top-padding text_color">
                { Events && Events != null && Events.length > 0 ? Events.map(function (item, index) {
                  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                  ];

                  const Estartdate = moment(item.event_date).format('MM/DD/YYYY');
                  const Eenddate = moment(item.event_end_date).format('MM/DD/YYYY');

                  var eventDetails = item.details;
                  const details = eventDetails.slice(0, 150);

                  const momentdate = moment(item.event_date).format('YYYY-MM-DD');
                  const CurrentYear = moment().format('YYYY');

                  const date = new Date(item.event_date);
                  const event_month = monthNames[date.getMonth()];
                  var event_day = date.getUTCDate();
                  //var videoTitle = item.title;
                  //const title = videoTitle.slice(0, 20);
                  const title = this.truncate(item.title, 4);
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 events_page" key={index.toString()}>
                          <a href={'/event-details/' + item.id}>
                            <div className="latest_events">
                              <div className="event_header">
                                <div className="event_red_side">{event_month + ' ' + event_day}</div>
                                <div className="event_black_side">{CurrentYear}</div>
                              </div>
                              <div className="event_content">
                                <h3>{item.title}</h3>
                                <p>{details}</p>
                                <div className="start_end_date">
                                  <span>Start Date : {Estartdate}</span>
                                  <span>End Date : {Eenddate}</span>
                                </div>
                                <div className="arrow_view_more">
                                  <img src={IMAGES.arrow} alt="arrow" />
                                  <span>
                                    VIEW MORE
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                      </div>
                    );
                }, this) : <div className="no_data">{'No Events'}</div>}
               </div>
             </Container>

            {/* Footer: src/components/common/Footer */}
            <Footer pageName="home" />
        </React.Fragment>
      );
  }
}

export default Events;
