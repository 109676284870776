import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
// import { CartReducers } from 'react-cart-components';
import cartReducer from './components/reducers/cartReducer';

import "./index.css";
import "./assets/scss/css/animate.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";


const store = createStore(cartReducer);


ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
