import React, { Component } from "react";
import { Cart } from 'react-cart-components';
import { NavLink, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, NavDropdown, Nav, Dropdown } from "react-bootstrap";
import base_url from '../../common/baseUrl';
import IMAGES from '../../common/images';

class NavBarPreLogin extends Component {
  constructor() {
      super();
      this.state = {
        currentUser: null
      };
      this.handleCheckout = this.handleCheckout.bind(this);
      this.productToRemove = this.productToRemove.bind(this);
  }
    async componentDidMount() {
        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("is-sticky");
            } else {
                elem.classList.remove("is-sticky");
            }
        });
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        this.setState({ scrollWithOffset });

        const currentSession = await JSON.parse(localStorage.getItem("session"));
        //console.log('currentSession', currentSession);
        this.setState({
          currentUser: currentSession
        });
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    onLogout = () => {
      localStorage.removeItem('session');
      localStorage.removeItem('access_token');
      window.location.href = '/';
    }

    handleCheckout = (data) => {
      //console.log('product_items',JSON.stringify(data));
      localStorage.setItem('product_items', JSON.stringify(data));
      window.location.href = '/checkout';
    }

    productToRemove = () => {
      alert('remove product');
    }

    render() {
      const { currentUser } = this.state;
        return (
            <React.Fragment>
            {/*<Cart currency="USD" handleCheckout={this.handleCheckout} productToRemove={this.productToRemove} />*/}
                <Navbar
                    // sticky="top"
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-lg navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                      <div className="row header_row">
                        <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                          {this.props.pageName === "home" ? (
                              <React.Fragment>
                                  <Link
                                      activeClass="active"
                                      to="home"
                                      spy={true}
                                      smooth={true}
                                      offset={-200}
                                      duration={800}
                                      className="navbar-brand"
                                  >
                                      <img src={IMAGES.logo} alt="logo" />
                                  </Link>
                              </React.Fragment>
                          ) : (
                              <LinkContainer exact to="/">
                                  <Navbar.Brand className="navbar-brand logo">
                                      <img src={IMAGES.logo} alt="logo" />
                                  </Navbar.Brand>
                              </LinkContainer>
                          )}

                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn"
                        />
                          <Navbar.Collapse id="basic-navbar-nav">
                              <Nav>
                                <React.Fragment>

                                  <a className="trialLink" href="#trial">REQUEST TRIAL</a>

                                  {/*<LinkContainer to="/products">
                                    <Nav.Link>PRODUCTS</Nav.Link>
                                  </LinkContainer>*/}

                                </React.Fragment>
                              </Nav>
                          </Navbar.Collapse>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding">
                          <div className="header_right_side">
                            <span><Link to="/signup">SIGNUP</Link> <span style={{ color: '#ffffff' }}>/</span> <Link to="/signin">SIGNIN</Link></span>
                          </div>
                        </div>
                      </div>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default NavBarPreLogin;
