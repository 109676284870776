import React, { Component } from "react";
import {Elements, StripeProvider, CardElement} from 'react-stripe-elements';
import { Container } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import "react-modal-video/css/modal-video.min.css";
//Components List
import CheckOutPageForm from '../components/common/CheckOutPageForm';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import IMAGES from '../common/images';


class CheckoutPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        inlineLoading: false,
        ProductData: null,
        Total_Amount: null,
        creditCards: null,
      };
  }

  async componentDidMount() {
    const currentUser = await JSON.parse(localStorage.getItem("session"));
    if (!currentUser) { window.location.href = "/signin"; }
    this.setState({
      ProductData: JSON.parse(localStorage.getItem("allEntries")),
      Total_Amount: JSON.parse(localStorage.getItem("total")),
    });
  }

  renderCartItems = () => {
    const { ProductData } = this.state;
    console.log(ProductData);
    return (
    ProductData && ProductData.length > 0 ? ProductData.map((item, index) => {
        return (
          <tr>
            <td>{item.title}<br /><span className="checkOut_qty">(${item.price} x {item.quantity})</span></td>
            <td>${item.price * item.quantity}</td>
          </tr>
        );
      }) : <tr><td colspan='4'>Cart is Empty</td></tr>
    );
  }

  render() {
    const { inlineLoading, ProductData, Total_Amount } = this.state;
    //console.log('Total_Amount_Quantity', Total_Amount_Quantity);
      return (
        <React.Fragment>
          {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </div> : ''}
          <Helmet>
              <meta charSet="utf-8" />
              <title>Checkout | Keion Henderson</title>
          </Helmet>
            {/* NavBar: src/components/common/NavBar */}
            <NavBar />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                <Container>
                  <div className="music_title">
                    <span>Checkout</span><img src={IMAGES.eventsIcon} alt="music" />
                  </div>
                </Container>
              </div>
            </div>
            <Container>
            <div className="row top-padding">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <StripeProvider apiKey="pk_test_5K1uUmTuynX6nJYHhC44NMo2">
                  <div className="example">
                    <Elements>
                      <CheckOutPageForm />
                    </Elements>
                  </div>
                </StripeProvider>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="product_main_div">
                  <div className="prod_details cart_table">
                    <h2>Products</h2>
                      <table className="table table-dark table-striped">
                        <thead>
                          <tr>
                            <th>ITEM</th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderCartItems()}
                        </tbody>
                      </table>
                      <table className="table table-dark table-striped">
                        <tbody>
                          <td className="total">TOTAL </td>
                          <td className="total bg_total">${Total_Amount}</td>
                        </tbody>
                    </table>
                  </div>
                </div>
               
              </div>
            </div>
            </Container>

            <Footer pageName="home" />
        </React.Fragment>
      );
  }
}

export default CheckoutPage;
