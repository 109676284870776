import React, { Component } from "react";
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../components/common/CheckoutForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dimmer, Loader } from 'semantic-ui-react';
import {Helmet} from "react-helmet";
import jsonFetch from '../common/api';
import IMAGES from '../common/images';

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class SubscribePage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        inlineLoading: false
      };
  }

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    const { inlineLoading } = this.state;
      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Subscription | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />

              <div className="page_bg">
                <div className="signup_form">
                {inlineLoading ? <div tabIndex="0" autoFocus="true" id="inlineDim" className="inlineDim">
                  <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                  </Dimmer>
                </div> : ''}
                  <h1>Subscribe</h1>
                    <StripeProvider apiKey="pk_test_5K1uUmTuynX6nJYHhC44NMo2">
                      <div className="example">
                        <Elements>
                          <CheckoutForm />
                        </Elements>
                      </div>
                    </StripeProvider>
                </div>
              </div>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default SubscribePage;
