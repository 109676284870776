import React, { Component } from "react";
import { connect } from 'react-redux';
import { NavLink, Link } from "react-router-dom";
import Pagination from 'react-js-pagination';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import { Form, Input } from 'semantic-ui-react';
import jsonFetch from '../common/api';
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';
import { removeItem,addQuantity,subtractQuantity} from '../components/actions/cartActions'

//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class Cart extends Component {
  constructor() {
      super();
      this.state = {
        inlineLoading: false,
        Products: null,
        cartLists: '',
        Total: '',
        activePage: 1,
      };
      this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {

    console.log("cartItems: ",JSON.parse(localStorage.getItem("allEntries")));
    const currentUser = await JSON.parse(localStorage.getItem("session"));
    if (!currentUser) { window.location.href = "/signin"; }
    this.setState({
      cartLists: JSON.parse(localStorage.getItem("allEntries")),
      Total: JSON.parse(localStorage.getItem("total")),
    });
    // this.Dataload(1);
  }

  //to remove the item completely
  handleRemove = (id)=>{
    this.props.removeItem(id);
    toast('Item Removed from Cart');
    setTimeout(
      function () {
        window.location.reload();
      },
      3000
    );
  }
  //to add the quantity
  handleAddQuantity = (id)=>{
      this.props.addQuantity(id);
      window.location.reload();
      // toast('Quantity Incremented');
  }
  //to substruct from the quantity
  handleSubtractQuantity = (id)=>{
      this.props.subtractQuantity(id);
      window.location.reload();
      // toast('Quantity Subtracted');
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.Dataload(pageNumber);
  }

  renderCartItems = () => {
    const { cartLists } = this.state;
    console.log(cartLists);
    return (
    cartLists && cartLists.length > 0 ? cartLists.map((item, index) => {
        return (
          <tr>
            <td><a onClick={()=>{this.handleRemove(item.id)}} alt="Remove Item" title="Remove Item"><i class="icofont-close icon cross" /></a> <img src={item.img} /> {item.title}</td>
            <td>${item.price}</td>
            <td><a onClick={()=>{this.handleSubtractQuantity(item.id)}} alt="Subtract Quantity" title="Subtract Quantity"><i class="icofont-minus icon" /></a><span className="quantity">{item.quantity}</span><a onClick={()=>{this.handleAddQuantity(item.id)}} alt="Add Quantity" title="Add Quantity"><i class="icofont-plus icon" /></a></td>
            <td>${item.price * item.quantity}</td>
          </tr>
        );
      }) : <tr><td colspan='4'>Cart is Empty</td></tr>
    );
  }

  render() {
    // console.log(this.props);
    // console.log(this.state.AllProductList);
    // const { AllProductList } = this.state;
    const { inlineLoading, Products, activePage, Total } = this.state;

      return (
          <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products | Keion Henderson</title>
            </Helmet>
              {/* NavBar: src/components/common/NavBar */}
              <NavBar />
              {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              </div> : ''}

              <div className="primary_bg_section">
                <Container>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                      <div className="music_title">
                        <span>Cart</span><img src={IMAGES.eventsIcon} alt="music" />
                      </div>
                  </div>
                </div>
                </Container>
              </div>

              <Container>



                <div className="row top-padding cart_table">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <a href="/products" className="btn btn-secondary">CONTINUE SHOPPING</a>
                    <p></p>
                    <table className="table table-dark table-striped">
                      <thead>
                        <tr>
                          <th>ITEM</th>
                          <th>PRICE</th>
                          <th>QUANTITY</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderCartItems()}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row cart_table">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h2 className="text_color">Cart Total</h2>
                    <table className="table table-dark table-striped">

                      <tbody>
                        <td className="total">TOTAL </td>
                        <td className="total bg_total">${Total}</td>
                      </tbody>
                    </table>
                    <a href="/checkout" className="btn btn-secondary cartbtn">PROCEED TO CHECKOUT</a>
                  </div>
                </div>

              </Container>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

const mapStateToProps = (state)=>{
    return {
        items: state.addedItems
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        removeItem: (id)=>{dispatch(removeItem(id))},
        addQuantity: (id)=>{dispatch(addQuantity(id))},
        subtractQuantity: (id)=>{dispatch(subtractQuantity(id))}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Cart)
