import React, { Component } from "react";
import { Container } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class PageNotFound extends Component {
  constructor() {
      super();
      this.state = {
        inlineLoading: false,
      };
  }

  componentDidMount(){
    this.setState({inlineLoading: true});
    this.setState({inlineLoading: false});
  }

  render() {
    const { inlineLoading } = this.state;
      return (
        <React.Fragment>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Page Not Found | Keion Henderson</title>
          </Helmet>

          {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </div> : ''}

            <NavBar />

            <Container>
              <div className="row top-padding">

                  <div className="col-lg-12 col-md-12">
                    <div className="notfound">
                      <h1>404</h1>
                      <h3>PAGE NOT FOUND</h3>
                      <p>we are sorry but the page that you are looking for doesn't exist or has been moved.</p>
                      <br />
                      <a className="btn btn-secondary" href="/">BACK TO HOMEPAGE</a>
                    </div>
                  </div>
              </div>
            </Container>

            {/* Footer: src/components/common/Footer */}
            <Footer pageName="home" />
        </React.Fragment>
      );
  }
}

export default PageNotFound;
