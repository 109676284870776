import React, { Component } from "react";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPlayer from 'react-player';
import { Container } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import "react-modal-video/css/modal-video.min.css";
//Components List
import jsonFetch from '../common/api';
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import base_url from '../common/baseUrl';
import IMAGES from '../common/images';

let currentUser;

class PopularOnLhc extends Component {
  constructor(props) {
      super(props);
      this.state = {
        inlineLoading: false,
        PLHC: null,
        visible: false,
        videoUrl: null,
        currentUser: null,
        Categories: null,
      };

      this.markFav = this.markFav.bind(this);
      this.getUniqueCategories = this.getUniqueCategories.bind(this);
      this.mustLoggedIn = this.mustLoggedIn.bind(this);
      this.playVideo = this.playVideo.bind(this);
  }

  async componentDidMount() {
    document.getElementById('HEADER').scrollIntoView();
    const currentSession = await JSON.parse(localStorage.getItem("session"));
    currentUser = currentSession;
    if (!currentUser) { window.location.href = "/signin"; }
    this.setState({
      currentUser: currentSession
    });
    this.Dataload();
  }

  Dataload = async () => {
    this.setState({inlineLoading: true});
    const params = JSON.stringify({type: 21});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('loadAssets', { body: body, method: 'POST' });
    console.log("PLHC page", response.data);
    this.setState({inlineLoading: false});
    if(response.success === 1){
      const temp = response.data.sort(function(a, b) {
        return parseInt(b.playcount) - parseInt(a.playcount);
      });

      this.setState({
        PLHC: temp,
      });

      let categories = [];
      response.data.map(function(data, index){
        categories.push({
          id: data.category.id,
          title: data.category.title,
        });
      });

      const categoriesData = this.getUniqueCategories(categories, 'title');

      this.setState({
        Categories: categoriesData
      });

    }
    else{
      toast(response.message);
    }
  }

  LHCDataload = async () => {
    const params = JSON.stringify({type: 21});
    const body = JSON.stringify({'paramsArray':params});
    const response = await jsonFetch('loadAssets', { body: body, method: 'POST' });
    if(response.success === 1){
      const temp = response.data.sort(function(a, b) {
        return parseInt(b.playcount) - parseInt(a.playcount);
      });

      this.setState({
        PLHC: temp,
      });

      let categories = [];
      response.data.map(function(data, index){
        categories.push({
          id: data.category.id,
          title: data.category.title,
        });
      });

      const categoriesData = this.getUniqueCategories(categories, 'title');

      this.setState({
        Categories: categoriesData
      });

    }
    else{
      toast(response.message);
    }
  }

  getUniqueCategories(arr, comp) {
    const unique = arr
       .map(e => e[comp])
     // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);
   return unique;
  }

  async playVideo(media) {
    //console.log('media', media);
    const { currentUser } = this.state;

    if (media.is_paid === 1 && currentUser && currentUser != null) {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });

    } else if (media.is_paid === 1 && currentUser === null) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="delete_popup">
              <h3>Please loggedIn to watch this video, it's Paid!</h3>
              <button type="button" onClick={onClose}>Cancel</button>
              <button
                type="button"
                onClick={() => {
                  window.location.href = '/signin';
                }}
              >
                {'Login'}
              </button>
            </div>
          );
        }
      });
    } else {
      this.setState({
        visible: true,
        videoUrl: media.video_url
      });

      const params = JSON.stringify({});
      const body = JSON.stringify({ paramsArray: params });
      const response = await jsonFetch('playcount/' + media.id, { body, method: 'POST' });
      //console.log('playcount response', response);
    }

  }

  truncate(str, no_words) {
    return str.split(" ").splice(0,no_words).join(" ");
  }

  renderVideos = (catId, global) => {
    const result = this.state.PLHC.filter(items => items.type === catId);
    return (
      result.map(function(item, index) {
        //var videoTitle = item.title;
        //const title = videoTitle.slice(0, 20);
        const title = global.truncate(item.title, 4);
        return (
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 events_page" key={index.toString()}>
            <div>
              { currentUser && currentUser != null ?
                item.favorites && item.favorites.length ? <div className="heart_icon"> <i className="icofont-heart" /></div> : <div className="heart_icon">
                <i className="icofont-heart fav_heart" onClick= {() => { global.markFav(item.id) }} style={{ cursor: "pointer" }} />
              </div> : <div className="heart_icon">
                <i className="icofont-heart fav_heart" onClick= {() => { global.mustLoggedIn()}} style={{ cursor: "pointer" }} />
              </div> }
            </div>

            <div className="favorites_item" onClick= {() => global.playVideo(item) }>
              <img src={item.img_url == null ? IMAGES.Trending1 :  base_url.baseImageUpload + item.img_url} alt="conference" />

              <div className="data_on_hover">
                {item.category.title != null ? item.category.title : 'No Category' }
              </div>

              <a href="javascript:void(0)" className="btn btn-secondary popular_slider_btn">
                <span>{title}</span><i className="icofont-ui-play" />
              </a>
            </div>
          </div>
        )
      })
   );
 }

  async markFav(fav_id){
    const params = JSON.stringify({});
    const body = JSON.stringify({ paramsArray: params });
    const response = await jsonFetch('fav/' + fav_id, { body, method: 'POST' });
    if (response.success === 1) {
      toast(response.message);
      this.LHCDataload();
    } else {
      toast(response.message);
    }
  }

  mustLoggedIn = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete_popup">
            <h3>Please login to mark as favorite!</h3>
            <button type="button" onClick={onClose}>Cancel</button>
            <button
              type="button"
              onClick={() => {
                window.location.href = '/signin';
              }}
            >
              {'Login'}
            </button>
          </div>
        );
      }
    });
  }

  hide() {
    this.setState({
      visible: false,
    });

    setTimeout(()=> {
      this.setState(()=> ({ videoUrl: null }))
    }, 700);
  }

  render() {
    const { Categories, currentUser, inlineLoading, PLHC, visible, videoUrl, } = this.state;
      return (
        <React.Fragment>
          {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </div> : ''}
          <Helmet>
              <meta charSet="utf-8" />
              <title>Popular On LHC | Keion Henderson</title>
          </Helmet>

          <Rodal duration={1000} closeOnEsc={true} visible={visible} onClose={this.hide.bind(this)}>
            <ReactPlayer url={videoUrl} loop={true} playing controls volume={1} width='100%'  />
          </Rodal>

            <NavBar />

            <div className="row" style={{marginBottom: '100px'}}>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                <Container>
                  <div className="music_title">
                    <span>Popular On LHC</span><img src={IMAGES.eventsIcon} alt="music" />
                  </div>
                </Container>
              </div>
            </div>
            <Container>

              { Categories && Categories != null && Categories.length > 0 ? Categories.map(function (item, index) {
                return (
                  <div key={index.toString()}>
                    <div className="section_title top-padding"><span>{item.title}</span></div>
                    <div className="row text_color">
                      {this.renderVideos(item.id, this)}
                    </div>
                  </div>
                );
              }, this) : <div className="no_data">{'No LHC Data'}</div>}

            </Container>

            {/* Footer: src/components/common/Footer */}
            <Footer pageName="home" />
        </React.Fragment>
      );
  }
}

export default PopularOnLhc;
