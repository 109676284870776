import React, { Component } from "react";
import moment from 'moment';
import ReactPlayer from 'react-player';
import "react-modal-video/css/modal-video.min.css";
import { Container } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { Dimmer, Loader } from 'semantic-ui-react';
import jsonFetch from '../common/api';
import IMAGES from '../common/images';
//Components List
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class EventDetailsPage extends Component {
  constructor() {
      super();
      this.state = {
        EventTitle: null,
        EventDetails: null,
        eventDate: null,
        videoUrl: null,
        inlineLoading: false,
        EventDay: null,
        EventMonth: null,
        Event_Date: null,
        Event_Start_Time: null,
        Event_End_Time: null,
        All_Day: null,
        Event_Address: null,
      };
  }

  async componentDidMount(){
    this.Dataload();
  }

  async Dataload () {
    this.setState({ inlineLoading: true });

    const event_id = this.props.match.params.id;

    const paramsArray = {}
    const response = await jsonFetch('getEvents', { body: paramsArray, method: 'POST' });
    const EventItem = response.data.filter(items => items.id === parseInt(event_id));
    console.log('EventItem', EventItem);
    this.setState({inlineLoading: false});

    const EventDate = EventItem[0].event_date;
    const Event_Day = moment(EventDate).format('dddd');

    const monthNames = ["January", "Febuary", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(EventDate);
    const event_month = monthNames[date.getMonth()];
    var event_date = date.getUTCDate();

    this.setState({
      EventTitle: EventItem[0].title,
      EventDetails: EventItem[0].details,
      videoUrl: EventItem[0].video_url,
      EventDay: Event_Day,
      EventMonth: event_month,
      Event_Date: event_date,
      Event_Start_Time: EventItem[0].start_time,
      Event_End_Time: EventItem[0].end_time,
      All_Day: parseInt(EventItem[0].all_day),
      Event_Address: EventItem[0].event_address,
    });
  }

  render() {
    const { videoUrl, Event_Start_Time, Event_End_Time, All_Day, Event_Address, EventDay, EventMonth, Event_Date,  EventTitle, EventDetails, inlineLoading } = this.state;
      return (
          <React.Fragment>
            {inlineLoading ? <div tabIndex="0" id="inlineDim" className="inlineDim">
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </div> : ''}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Event Details | Keion Henderson</title>
            </Helmet>
              <NavBar />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 music_back">
                  <Container>
                    <div className="music_title">
                      <span>{EventTitle && EventTitle != null ? EventTitle : "No Event Title"}</span>
                      <img src={IMAGES.eventsIcon} alt="music" />
                    </div>
                  </Container>
                </div>
              </div>

              <Container>
                <div className="event_data">

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h1>{EventTitle && EventTitle != null ? EventTitle : "No Event Title"}</h1>
                    </div>
                  </div>

                  <div className="row">

                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">

                      <div className="events_date_div">
                        <div className="event_date">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          <div className="event_day">
                            <div className="start_text">{EventDay ? EventDay : null}</div>
                            <span>{EventMonth ? EventMonth : null} {Event_Date ? Event_Date : null}</span>
                          </div>
                        </div>
                      </div>

                      <div className="events_time_div">
                        {All_Day === 0 ? <div className="event_times">
                          <i class="fa fa-clock" aria-hidden="true"></i>
                          {Event_Start_Time && Event_Start_Time !== null ? <div className="event_start">
                            <div className="start_text">Starts</div>
                            <span>{Event_Start_Time ? Event_Start_Time : null}</span>
                          </div> : null}
                          {Event_End_Time && Event_End_Time !== null ? <div className="event_start">
                            <div className="start_text">Ends</div>
                            <span>{Event_End_Time ? Event_End_Time : null}</span>
                          </div> : null }
                        </div> : <div className="event_times">
                          <i class="fa fa-clock" aria-hidden="true"></i>
                          <div className="event_start">
                            <div className="start_text">All Day</div>
                          </div>
                        </div>}
                      </div>

                      <div className="event_location">
                        <img src={IMAGES.LocIcon} alt="location"/>
                        <div className="event_info">
                          {Event_Address && Event_Address != null ? Event_Address : "No Event Address"}
                        </div>
                      </div>

                    </div>

                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                      <div className="event_details">
                        {EventDetails && EventDetails != null ? EventDetails : "No Event Details"}
                      </div>
                      {videoUrl && videoUrl != null ? <ReactPlayer url={videoUrl} playing loop={true} controls volume={1} width='100%' /> : null}
                    </div>

                  </div>

                </div>

              </Container>

              {/* Footer: src/components/common/Footer */}
              <Footer pageName="home" />
          </React.Fragment>
      );
  }
}

export default EventDetailsPage;
