import React, {Component} from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import jsonFetch from '../../common/api';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        inlineLoading: false,
    };
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    this.setState({inlineLoading: true});
  let {token} = await this.props.stripe.createToken({name: "Name"});
  //console.log('token', token);
  if (!token) {
    this.setState({inlineLoading: false});
    alert('Payment Failed, Please try again');
    return false
  }
  const params = JSON.stringify({ stripe_key: token.id });
  const body = JSON.stringify({ paramsArray: params });
  const response = await jsonFetch('manageStripe', { body, method: 'POST' });
  //console.log('manageStripe', response);
  this.setState({inlineLoading: false});
  if (response.success === 1) {
    localStorage.setItem('session', JSON.stringify(response.data));
    toast("Payment Successful");
    window.location.href = '/';
  } else {
    toast(response.message);
    alert(response.message);
  }
}

  render() {
    const { inlineLoading } = this.state;
    return (
      <div className="checkout">
        {inlineLoading ? <div tabIndex="0" autoFocus="true" id="inlineDim" className="inlineDim">
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </div> : ''}
        <p>Please subscribe to our monthly Subscription</p>
        <CardElement style={{ base: {color: '#fff'} }} />
        <button className="btn btn-secondary" onClick={this.submit}>Confirm</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
